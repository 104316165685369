(() => {
  angular
    .module('app')
    .controller(
      'common.views.manageTwoFactorAuth.updateTwoFactorAuthenticationModal',
      UpdateTwoFactorAuthenticationController
    );

  UpdateTwoFactorAuthenticationController.$inject = [
    '$uibModalInstance',
    'isEnable',
    'emailAddress'
  ];

  function UpdateTwoFactorAuthenticationController($uibModalInstance, isEnable, emailAddress) {
    const vm = this;

    vm.confirm = confirm;
    vm.cancel = cancel;
    vm.isEnable = isEnable;
    vm.emailAddress = emailAddress

    init();

    function init() {}

    function confirm() {
      $uibModalInstance.close({
        confirm: true
      });
    }

    function cancel() {
      $uibModalInstance.dismiss();
    }
  }
})();
