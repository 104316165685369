(() => {
  const componentId = 'hostInsurersCreateEditProperties';

  angular.module('app').component(componentId, {
    require: {
      container: '^hostInsurersCreateEditContainer',
    },
    bindings: {
      data: '<',
      isCreate: '<',
      countryCode: '=',
    },
    templateUrl: require('./properties.component.html'),
    controller: InsurerCreatePropertiesController,
    controllerAs: 'vm',
  });

  InsurerCreatePropertiesController.$inject = [
    '$scope',
    'abp.services.app.commonLookup',
    'Hms.Insurance.Insurer',
    'Tenant',
    'Hms.Users.User',
    'appConstants',
    'abp.services.app.insurer',
  ];

  function InsurerCreatePropertiesController(
    $scope,
    commonLookupSvc,
    constsInsurer,
    constsTenant,
    constsUser,
    appConstants,
    insurerSvc
  ) {
    const vm = this;

    vm.constsInsurer = constsInsurer;
    vm.constsTenant = constsTenant;
    vm.constsUser = constsUser;
    vm.loading = 0;
    vm.saving = 0;
    vm.isEditing = false;
    vm.permissions = {
      edit: abp.auth.isGranted('Host.NewInsurers.EditProperties'),
      create: abp.auth.isGranted('Host.NewInsurers.Create'),
    };
    vm.hasTxtFileGeneration = abp.setting.getBoolean('Hms.Feature.TxtFileGeneration');

    const fileExtensions = `|${appConstants.fileUpload.imageExtensions.join('|')}|`;

    vm.selectedAccountOwnerDetails = selectedAccountOwnerDetails;
    vm.validateForm = validateForm;
    vm.getPayload = getPayload;
    vm.isDirty = isDirty;
    vm.setPristine = setPristine;
    vm.getTxtSchemes = getTxtSchemes;
    vm.updateCountryCodeState = updateCountryCodeState;

    vm.$onInit = () => {
      vm.container.addWidget(this);

      if (vm.isCreate) {
        vm.data = {
          logoAttachment: [],
          countryCode: abp.setting.get('Hms.General.DefaultCountryCode'),
        };
        vm.countryCode = vm.data.countryCode;
      }

      getAccountOwners();
      getTimeZones();
      getTxtSchemes();
    };

    vm.uploadConfig = {
      objectType: 'InsurerLogo',
      accept: 'image/*',
      itemsLimit: 1,
      filters: [
        {
          name: 'fileFilter',
          fn: (n) => {
            const t = `|${n.type.slice(n.type.lastIndexOf('/') + 1)}|`;
            if (fileExtensions.indexOf(t) === -1) {
              abp.message.warn(App.localize('File_Warn_FileType'));
              return false;
            }
            return true;
          },
        },
        {
          name: 'sizeFilter',
          fn: (n) => {
            const sizeLimit = 52428800;
            if (n.size > sizeLimit) {
              abp.message.warn(App.localize('File_Warn_XSizeLimit', sizeLimit / 1024 / 1024));
              return false;
            }
            return true;
          },
        },
      ],
    };

    vm.creationInput = {
      admin: {
        autoGeneratePassword: true,
        shouldChangePasswordOnNextLogin: true,
      },
    };

    function getAccountOwners() {
      vm.loading += 1;
      commonLookupSvc
        .getAccountOwners()
        .success((data) => {
          vm.accountOwners = data;
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function getTimeZones() {
      vm.loading += 1;
      commonLookupSvc
        .getTimeZones()
        .success((data) => {
          vm.timeZones = data;
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function getTxtSchemes() {
      vm.loading += 1;
      insurerSvc
        .getAllTxtSchemeForInsurers()
        .success((data) => {
          vm.txtSchemes = data;
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function selectedAccountOwnerDetails(accountOwnerId) {
      const accountOwner = _.find(vm.accountOwners, ['id', accountOwnerId]);
      vm.currencyCode = accountOwner.currencyCode;
      vm.countryCode = accountOwner.countryCode;
      vm.paymentMode = accountOwner.paymentAccountNumberMode;
    }

    // Functions utilised in container file.

    // Force validation on this form.
    // True if valid, otherwise, false.

    function validateForm(error) {
      const form = $scope.insurersCreateEditPropertiesForm;
      App.touchFormErrors(form);
      error = !form.$valid;
    }

    // Generate the payload from this form to send to InsurersAppService API.

    function getPayload(settings) {
      let payload = {
        properties: vm.data,
      };

      if (vm.isCreate) {
        payload = _.extend({}, vm.creationInput, payload);
      }
      
      _.extend(settings, payload);
    }

    function isDirty() {
      return $scope.insurersCreateEditPropertiesForm.$dirty;
    }

    function setPristine() {
      $scope.insurersCreateEditPropertiesForm.$setPristine();
    }

    function updateCountryCodeState() {
      vm.countryCode = vm.data.countryCode;
    }
  }
})();
