(() => {
  angular
    .module('app')
    .component('hostCorporatesMasterPoliciesMasterPolicyEligibilityEnrolmentRulesComponent', {
      templateUrl: require('./enrolmentRules.component.html'),
      controller: EnrolmentRulesController,
      controllerAs: 'vm',
      bindings: {
        data: '<',
        isPrincipal: '<',
        relationship: '@',
        isCreate: '<',
        isRenewal: '<',
        isEdit: '<'
      },
    });
	
	EnrolmentRulesController.$inject = ['countries'];

  function EnrolmentRulesController(countries) {
    const vm = this;
    vm.loading = 0;

    vm.countries = countries;

    vm.coverDependent = false;
    vm.isCovered = '';
    vm.coverDependentLabel = App.localize('Uncovered');

    vm.toggleDependent = toggleDependent;

    function init() {
      // Assign value to isPrincipal and relationship based on vm.isPrincipal binded value.
      
      if (vm.isCreate || vm.isRenewal) {
        if (vm.isPrincipal) {
          vm.data.isPrincipal = true;
        }
        else {
          vm.data.isPrincipal = false;
          vm.data.relationship = vm.relationship;
        }
      }
      else {
        const blackListedCountries = _.filter(vm.countries, (n)=> 
          _.includes(vm.data.nationalityCodes, n.code))

        vm.countryNames = _.map(blackListedCountries, m => m.name).join(', ');
      }

      if (vm.data.isCovered) toggleDependent();
    }

    init();

    function toggleDependent() {

      // Only applies to dependent.

      if (vm.data.isPrincipal) return;

      // Flip on/off switch.

      vm.coverDependent = !vm.coverDependent;

      if (vm.coverDependent) {
        vm.isCovered = 'active';
        vm.coverDependentLabel = App.localize('Covered');
      }

      if (!vm.coverDependent) {
        vm.isCovered = '';
        vm.coverDependentLabel = App.localize('Uncovered');
      }

      vm.data.isCovered = vm.coverDependent;
    }
  }
})();
