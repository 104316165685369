(() => {
  angular.module('finance')
    .controller('finance.views.settings.index', FinanceSettingsController);

  FinanceSettingsController.$inject = [
    'abp.services.finance.settings',
    'Abp.Configuration.Setting',
    'abp.services.finance.commonLookup',
  ];

  function FinanceSettingsController(settingsSvc, constsAbpConfig, commonLookupSvc) {
    const vm = this;
    vm.constsAbpConfig = constsAbpConfig;
    vm.save = save;
    vm.settings = null;
    vm.loading = 0;
    vm.currencyCode = abp.setting.get('Hms.General.CurrencyCode');

    init();

    function init() {
      getSettings();
      getTenancyNames();
    }

    function save() {
      let error = false;

      // Validate MyInvois form.

      error = !(vm.settings.features.delayDays === 0 || vm.settings.features.delayDays === 1);

      // Stop saving if any error occurs.

      if (error) {
        abp.notify.error(App.localize('GeneralInvalidFormInputError'));
        return;
      }

      convertToString();

      vm.loading += 1;
      settingsSvc
        .updateSettings(vm.settings)
        .success(() => {
          abp.notify.success(App.localize('SuccessfullySaved'));
          convertToArray();    
        })
        .finally(() => {
          vm.loading -= 1;
        })
    }

    function getSettings() {
      vm.loading += 1;

      settingsSvc
        .getSettings()
        .success((data) => {
          vm.settings = data;
          convertToArray();
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function getTenancyNames() {
      vm.loading += 1;

      commonLookupSvc
        .getTenancyNames()
        .success((data) => {
          vm.tenancyNames = data.tenancyNames;
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function convertToString() {
      if (vm.settings.features.curlecIntegrationCountries) {
        vm.settings.features.curlecIntegrationCountries = 
          vm.settings.features.curlecIntegrationCountries.join(", ");
      }
      else 
        vm.settings.features.curlecIntegrationCountries = null;

      if (vm.settings.features.curlecIntegrationTenants) {
        vm.settings.features.curlecIntegrationTenants = 
          vm.settings.features.curlecIntegrationTenants.join(", ");
      }
      else 
        vm.settings.features.curlecIntegrationTenants = null;
    }

    function convertToArray() {
      if (vm.settings.features.curlecIntegrationCountries) {
        vm.settings.features.curlecIntegrationCountries = 
          vm.settings.features.curlecIntegrationCountries.split(", ");
      }

      if (vm.settings.features.curlecIntegrationTenants) {
        vm.settings.features.curlecIntegrationTenants = 
          vm.settings.features.curlecIntegrationTenants.split(", ");
      }
    }
  }
})();
