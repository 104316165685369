(() => {
  angular.module('app').component('hostInpatientGuaranteeLettersCommonPatientDetailsComponent', {
    templateUrl: require('./patientDetails.component.html'),
    controller: PatientDetailController,
    controllerAs: 'vm',
    bindings: {
      patientId: '<',
    },
  });

  PatientDetailController.$inject = [
    '$uibModal',
    'abp.services.app.inpatientEmployee',
    'abp.services.app.hostEmployee',
  ];

  function PatientDetailController($uibModal, inpatientEmployeeSvc, hostEmployeeSvc) {
    const vm = this;
    vm.loading = 0;
    vm.$onInit = onInit;
    vm.hasInpatientClaimProcessingEnhancement = abp.setting.getBoolean(
      'Hms.Feature.InpatientClaimProcessingEnhancement'
    );

    vm.permissions = {
      canManageInsuranceEnrolmentFlag: abp.auth.isGranted(
        'Host.ManageMemberInsuranceEnrolmentFlag'
      ),
    };

    vm.openInsuranceEndorsementModal = openInsuranceEndorsementModal;
    vm.unflagInsuranceEndorsementFlaggedMember = unflagInsuranceEndorsementFlaggedMember;

    function onInit() {
      vm.loading += 1;
      inpatientEmployeeSvc
        .getInpatientPatientDetail({
          id: vm.patientId,
        })
        .success((data) => {
          vm.patient = data;
          vm.patientAgeText = App.localize('XYearsOld', vm.patient.age);
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function openInsuranceEndorsementModal() {
      $uibModal.open({
        templateUrl: require('../../../../../common/views/modals/memberInsuranceEndorsement/memberInsuranceEndorsement.modal.html'),
        controller: 'common.views.member.insuranceEndorsementModal as vm',
        backdrop: 'static',
        resolve: {
          employeeId: vm.patientId,
          relationship: () => vm.patient.relationship,
        },
      });
    }

    function unflagInsuranceEndorsementFlaggedMember() {
      if (!vm.permissions.canManageInsuranceEnrolmentFlag)
        return;
      
      vm.loading += 1;
      const updatedFlag = !vm.patient.isInsuranceEnrolmentFlagged;

      hostEmployeeSvc
        .unflagMemberInsuranceEndorsementFlag({
          employeeId: vm.patientId,
          isFlagged: updatedFlag,
        })
        .success(() => {
          vm.patient.isInsuranceEnrolmentFlagged = updatedFlag;
          abp.notify.info(App.localize('MemberInsuranceEnrolmentXUnflagged', vm.patient.name));
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }
  }
})();
