(() => {
  angular.module('app').controller('host.views.employees', HostEmployeeController);

  HostEmployeeController.$inject = [
    '$state',
    '$stateParams',
    '$uibModal',
    '$filter',
    'abp.services.app.hostEmployee',
    'Hms.Employees.EmployeeState',
    'Hms.Services.ServiceType',
    'Hms.PatientTickets.PatientTicketType',
    'Hms.Claims.ClaimType',
    'moment',
    'abp.services.app.tenant',
    'appConstants',
    'Hms.Finance.TransactionTenantType',
  ];

  function HostEmployeeController(
    $state,
    $stateParams,
    $uibModal,
    $filter,
    hostEmployeeSvc,
    enumEmployeeState,
    enumServiceType,
    enumPatientTicketType,
    enumClaimType,
    moment,
    tenantSvc,
    appConstants,
    enumTransactionTenantType
  ) {
    const vm = this;

    vm.currencyCode = abp.setting.get('Hms.General.CurrencyCode');
    vm.defaultMaxAllowance = parseFloat(abp.setting.get('Hms.General.MaxAllowanceAmountLimit'));
    vm.loading = 0;
    vm.employees = null;
    vm.requestParams = {
      filter: $stateParams.filter,
      includeRelated: $stateParams.includeRelated,
      checkInDate: $stateParams.checkInDate,
    };
    vm.hasHospitalizationWaitingPeriodEnhancement = abp.setting.getBoolean(
      'Hms.Feature.HospitalisationWaitingPeriodEnhancement'
    );
    vm.hasInsuranceEnhancementModule = abp.setting.getBoolean(
      'Hms.Feature.InsuranceEnhancementModule'
    );
    vm.hasPostHospitalizationGuaranteeLetterWorkflow = abp.setting.getBoolean(
      'Hms.Feature.PostHospitalizationGuaranteeLetterWorkflow'
    );
    vm.canApplyPostHospitalizationGL = false;

    vm.enums = {};
    vm.enums.employeeState = enumEmployeeState;
    vm.enums.serviceType = enumServiceType;
    vm.enums.patientTicketType = enumPatientTicketType;
    vm.enums.claimType = enumClaimType;
    vm.enums.transactionTenantType = enumTransactionTenantType;

    // Check for inpatient module enable for the application.

    vm.applicationHasInpatientModule = abp.setting.getBoolean('Hms.Feature.InpatientModule');
    vm.hasInpatientClaimProcessingEnhancement = abp.setting.getBoolean(
      'Hms.Feature.InpatientClaimProcessingEnhancement'
    );

    const hasManageInpatient = abp.auth.isGranted('Host.Claims.Inpatient.Manage');
    vm.permissions = {
      canViewTickets: abp.auth.isGranted('PatientTickets'),
      canReimburse: abp.auth.isGranted('PatientTickets.Reimbursement.Create') || hasManageInpatient,
      canCreateManualTicket:
        abp.auth.isGranted('PatientTickets.Host.Manual.Create') || hasManageInpatient,
      canViewClaims: abp.auth.isGranted('Host.Claims'),
      canCreateGuaranteeLetter: abp.auth.isGranted('GuaranteeLetters.Host.Create'),
      createManualTransaction: abp.auth.isGranted('HealthMetrics.Finance.ManualTransactionRequests.Create'),
      canManageInsuranceEnrolmentFlag: abp.auth.isGranted('Host.ManageMemberInsuranceEnrolmentFlag')
    };

    vm.getInpatientGlHistory = getInpatientGlHistory;
    vm.getEmployees = getEmployees;
    vm.getUserBgColor = getUserBgColor;
    vm.getUserInitial = getUserInitial;
    vm.toggleFlagged = toggleFlagged;
    vm.checkAccess = checkAccess;
    vm.viewClaimPolicy = viewClaimPolicy;
    vm.getUncoveredTreatments = getUncoveredTreatments;
    vm.getGLHistory = getGLHistory;
    vm.createGuaranteeLetter = createGuaranteeLetter;
    vm.viewExclusionList = viewExclusionList;
    vm.viewScheduleOfBenefits = viewScheduleOfBenefits;
    vm.createInpatientNprClaim = createInpatientNprClaim;
    vm.getAdmissionRecords = getAdmissionRecords;
    vm.getEmployeeSpecialNotes = getEmployeeSpecialNotes;
    vm.createPostHospitalizationGuaranteeLetter = createPostHospitalizationGuaranteeLetter;
    vm.createManualTransactionRequest = createManualTransactionRequest;
    vm.openInsuranceEndorsementModal = openInsuranceEndorsementModal;
    vm.unflagInsuranceEndorsementFlaggedMember = unflagInsuranceEndorsementFlaggedMember;

    init();

    function init() {
      if (vm.requestParams.filter) vm.getEmployees();
      vm.hasRegionalSystemSupport = abp.setting.getBoolean('Hms.Feature.RegionalSystemSupport');
    }
    function registerStateParams() {
      const processed = {};
      if (vm.requestParams.checkInDate)
        processed.checkInDate = moment(vm.requestParams.checkInDate).format('YYYY-MM-DD');

      $state.transitionTo($state.current, $.extend({}, vm.requestParams, processed), {
        notify: false,
      });
    }

    function getCorporateActiveServices() {
      const corporateIds = [];

      // Get distinct corporate ids.

      _.each(vm.employees, (employee) => {
        if (corporateIds.indexOf(employee.corporateId) < 0) {
          corporateIds.push(employee.corporateId);
        }
      });

      // Get corporate active services, assign to employees according to corporateId.

      _.each(corporateIds, (id) => {
        vm.loading += 1;
        tenantSvc
          .getTenantActiveServices({ id })
          .success((data) => {
            const corporateEmployees = vm.employees.filter((e) => e.corporateId === id);

            _.each(corporateEmployees, (employee) => {
              _.assign(employee, {
                activeServices: data,
              });
            });
          })
          .finally(() => {
            vm.loading -= 1;
          });
      });
    }

    function getEmployees() {
      if (vm.loading) return;

      registerStateParams();
      vm.loading += 1;
      hostEmployeeSvc
        .getEmployees(vm.requestParams)
        .success((data) => {
          vm.employees = data.items || [];
          _.each(vm.employees, (employee) => {
            const ticketServiceTypes = [];
            const isMasterPolicyExpired =
              employee.masterPolicy &&
              moment().startOf('day').utc() > moment(employee.masterPolicy.endDate).utc();

            _.each(employee.serviceTypes, (service) => {
              if (!service.isCustom) {
                service.name = App.localize(service.name);
              }

              service.benefits = [];
              if (service.coPay) {
                if (service.coPay.isPercentage) {
                  service.coPay.title = App.localize('CopayX', `${service.coPay.value}%`);
                  service.coPay.text = App.localize('CopayPercentage', `${service.coPay.value}%`);
                } else {
                  const valueText = $filter('currencyFormat')(service.coPay.value, vm.currencyCode);
                  service.coPay.title = App.localize('CopayX', valueText);
                  service.coPay.text = App.localize('CopayFixedAmount', valueText);
                }
              }
              if (service.balancePools && service.balancePools.length) {
                _.each(service.balancePools, (d) => {
                  let title = _.join(
                    _.map(d.benefitTypes, (bt) => {
                      if (bt.isCustom) {
                        let strCt = `CT${bt.id}`;
                        const idx = _.findIndex(
                          employee.customizedTreatments,
                          (ctn) => ctn.value === bt.id
                        );
                        if (idx !== -1) strCt = employee.customizedTreatments[idx].name;
                        return strCt;
                      }
                      if (bt.id === 251) {
                        return App.localize('Hospitalization');
                      }

                      return App.localize(bt.name);
                    }),
                    '/'
                  );

                  // TODO: Better design? localisation required

                  const isBudget = d.modifierType === 0 || d.modifierType === 2;
                  let cycle = '';
                  if (!d.isUnlimited) {
                    // eslint-disable-next-line default-case
                    switch (d.modifierCycle) {
                      case 0:
                        cycle = `every ${d.interval} years `;
                        break;
                      case 1:
                        cycle = 'annual ';
                        break;
                      case 2:
                        cycle = 'monthly ';
                        break;
                      case 3:
                        cycle = 'daily ';
                        break;
                      case 4:
                        cycle = 'per visit ';
                        break;
                      case 5:
                        cycle = '6 months ';
                        break;
                      case 6:
                        cycle = '4 months ';
                        break;
                      case 7:
                        cycle = '3 months ';
                        break;
                      case 8:
                        cycle = '2 months ';
                        break;
                    }
                    if (d.modifierType === 2 || d.modifierType === 3) cycle += 'overdraft ';
                  }
                  title += ` (${cycle}${isBudget ? 'budget' : 'visit'})`;

                  const isEmptyExcess = d.isExcess && !d.employeePortion;
                  if (!isEmptyExcess) {
                    service.benefits.push({
                      title,
                      isUnlimited: d.isUnlimited,
                      isExcess: d.isExcess,
                      isBudget,
                      limit: d.limit,
                      used: d.used,
                      locked: d.locked,
                      balance: d.balance,
                    });
                  }
                });
              }
              service.allowance = Math.min(service.allowance, vm.defaultMaxAllowance);

              if (service.serviceType === 5) {
                _.assign(employee, {
                  hasSpecialist: true,
                });
              }

              if (service.serviceType !== 4) {
                ticketServiceTypes.push({
                  canCreate: true,
                  name: service.name,
                  serviceType: service.serviceType,
                });
              }

              if (
                (service.serviceType === 7 && !isMasterPolicyExpired) ||
                service.serviceType === 8
              ) {
                _.assign(employee, {
                  hasInpatientBenefit: true,
                });
              }
            });

            if (employee.inpatientBenefits && employee.inpatientBenefits.length > 0) {
              _.assign(employee, {
                inpatientTypes: _.filter(employee.serviceTypes, (item) => item.isHospitalize),
              });

              _.each(employee.inpatientTypes, (p) => {
                const inpatientInfo = _.find(employee.inpatientBenefits, [
                  'serviceType',
                  p.serviceType,
                ]);

                vm.canApplyPostHospitalizationGL = vm.hasPostHospitalizationGuaranteeLetterWorkflow;

                if (inpatientInfo) {
                  p.supportPlanName = inpatientInfo.name;
                  p.supportPlanDisplayName = inpatientInfo.displayName;
                  p.supportPlanRemarks = inpatientInfo.remarks;

                  if (p.serviceType === 7) {
                    p.name = App.localize('Hospitalization');
                    if (employee.masterPolicy) {
                      p.name = App.localize('Insurance');
                      employee.masterPolicy.startDate = moment(
                        employee.masterPolicy.startDate
                      ).format('DD/MM/YYYY');
                      p.supportPlanName = `${p.supportPlanName} (${employee.masterPolicy.masterPolicyNumber})`;
                    }
                    employee.customizedTreatments.unshift({
                      canCreate: true,
                      name: App.localize('HospitalizationTicket'),
                      value: 7,
                    });

                    p.settings = inpatientInfo.hospitalizationSetting;
                    if (inpatientInfo.hospitalizationSetting.coPays != null) {
                      // eslint-disable-next-line prefer-destructuring
                      p.coPay = inpatientInfo.hospitalizationSetting.coPays[0];
                    }
                  } else if (p.serviceType === 8) {
                    p.name = App.localize('Maternity');
                    employee.customizedTreatments.unshift({
                      canCreate: true,
                      name: App.localize('MaternityTicket'),
                      value: 8,
                    });

                    p.settings = inpatientInfo.maternitySetting;
                    if (inpatientInfo.maternitySetting.coPays != null) {
                      // eslint-disable-next-line prefer-destructuring
                      p.coPay = inpatientInfo.maternitySetting.coPays[0];
                    }
                  }
                } else {
                  p.supportPlanName = employee.supportPlanName;
                  p.supportPlanDisplayName = employee.supportPlanDisplayName;
                  p.supportPlanRemarks = employee.supportPlanRemarks;
                }

                p.allowance = Math.min(p.allowance, vm.defaultMaxAllowance);

                if (p.coPay) {
                  if (p.coPay.isPercentage) {
                    p.coPay.title = App.localize('CopayX', `${p.coPay.value}%`);
                    p.coPay.text = App.localize('CopayPercentage', `${p.coPay.value}%`);
                  } else {
                    const valueText = $filter('currencyFormat')(p.coPay.value, vm.currencyCode);
                    p.coPay.title = App.localize('CopayX', valueText);
                    p.coPay.text = App.localize('CopayFixedAmount', valueText);
                  }
                }
              });

              employee.serviceTypes = _.filter(
                employee.serviceTypes,
                (item) => !item.isHospitalize
              );
            }

            employee.ticketServiceTypes = ticketServiceTypes;

            // Default the number of check-ins to be display to 1.
            employee.checkInsDisplayLimit = 1;

            // Default to non-hospitalization tab (0).
            employee.benefitsActiveTab = 0;
          });

          getCorporateActiveServices();
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function getUserBgColor(id) {
      return `user_bgcolor_${((id || 0) % 8) + 1}`;
    }

    function getUserInitial(user) {
      const fullName = (user.name || ' ').split(' ');
      let initial = '';
      for (let i = 0; i < fullName.length; i += 1) {
        if (fullName[i].length) {
          initial += fullName[i][0];
        }
      }
      if (initial && initial.length > 3) initial = `${initial[0]}`;
      return initial;
    }

    function toggleFlagged(employee) {
      if (vm.loading) return;
      const attemptFlag = !employee.isFlagged;

      vm.loading += 1;
      hostEmployeeSvc
        .flagEmployee({
          employeeId: employee.id,
          isFlagged: attemptFlag,
        })
        .success(() => {
          employee.isFlagged = attemptFlag;
          abp.notify.info(
            App.localize(
              employee.isFlagged ? 'PatientXFlagged' : 'PatientXUnflagged',
              employee.name
            )
          );
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function checkAccess(employeeId) {
      $uibModal.open({
        templateUrl: require('./checkAccessModal.html'),
        controller: 'host.views.employees.checkAccessModal as vm',
        backdrop: 'static',
        resolve: {
          employeeId() {
            return employeeId;
          },
        },
      });
    }

    function viewClaimPolicy(employee) {
      $uibModal.open({
        templateUrl: require('./claimPolicy/claimPolicy.modal.html'),
        controller: 'host.views.employees.claimPolicyModal as vm',
        size: 'lg',
        backdrop: 'static',
        resolve: {
          memberId() {
            return employee.id;
          },
        },
      });
    }

    function getUncoveredTreatments(employeeId, serviceType, serviceTypeName) {
      $uibModal.open({
        templateUrl: require('./uncoveredTreatmentTreeModal.html'),
        controller: 'host.views.employees.uncoveredTreatmentTreeModal as vm',
        backdrop: 'static',
        resolve: {
          employeeId() {
            return employeeId;
          },
          serviceType() {
            return serviceType;
          },
          serviceTypeName() {
            return serviceTypeName;
          },
        },
      });
    }

    function getGLHistory(employeeId) {
      $uibModal.open({
        templateUrl: require('./getGLHistoryModal.html'),
        controller: 'host.views.employees.getGLHistoryModal as vm',
        backdrop: 'static',
        size: 'lg',
        resolve: {
          employeeId() {
            return employeeId;
          },
        },
      });
    }

    function createGuaranteeLetter(employee) {
      $uibModal.open({
        templateUrl: require('../../../common/views/guaranteeLetters/createModal.html'),
        controller: 'common.views.guaranteeLetters.createModal as vm',
        backdrop: 'static',
        size: 'lg',
        resolve: {
          employee() {
            return employee;
          },
        },
      });
    }

    function createPostHospitalizationGuaranteeLetter(employee) {
      $uibModal.open({
        templateUrl: require('../../../common/views/postHospitalization/guaranteeLetters/createModal.html'),
        controller: 'common.views.postHospitalization.guaranteeLetters.createModal as vm',
        backdrop: 'static',
        size: 'lg',
        resolve: {
          employee() {
            return employee;
          },
        },
      });
    }

    function viewExclusionList(inpatientSettings) {
      $uibModal.open({
        templateUrl: require('./exclusionList/exclusionList.modal.html'),
        controller: 'host.views.employees.exclusionListModal as vm',
        backdrop: 'static',
        size: 'lg',
        resolve: {
          inpatientSettings,
        },
      });
    }

    function viewScheduleOfBenefits(serviceType, settings, employeeType) {
      $uibModal.open({
        templateUrl: require('./scheduleOfBenefits/scheduleOfBenefits.modal.html'),
        controller: 'host.views.employees.scheduleOfBenefitsModal as vm',
        backdrop: 'static',
        size: 'lg',
        resolve: {
          serviceType,
          hospitalizationSetting: settings,
          maternitySetting: settings,
          employeeType,
          countryInfo: {
            countryCode: null,
            currencyCode: null,
          },
        },
      });
    }

    function createInpatientNprClaim(claimType, employeeId) {
      $state.go('host.createInpatientNprClaim', {
        claimType,
        employeeId,
      });
    }

    function getAdmissionRecords(claimType, employeeId) {
      $uibModal.open({
        templateUrl: require('./getAdmissionRecordsModal.html'),
        controller: 'host.views.employees.getAdmissionRecordsModal as vm',
        backdrop: 'static',
        size: 'lg',
        resolve: {
          claimType() {
            return claimType;
          },
          employeeId() {
            return employeeId;
          },
        },
      });
    }

    function getInpatientGlHistory(employeeId, serviceType) {
      $uibModal.open({
        templateUrl: require('./inpatientGlHistory/inpatientGlHistory.modal.html'),
        controller: 'host.views.employees.inpatientGlHistory.inpatientGlHistoryModal as vm',
        backdrop: 'static',
        size: 'xl',
        resolve: {
          employeeId() {
            return employeeId;
          },
          serviceType() {
            return serviceType;
          },
        },
      });
    }

    function getEmployeeSpecialNotes(employeeId, specialNotes) {
      $uibModal
        .open({
          templateUrl: require('../../../common/views/modals/remarks/remarks.modal.html'),
          controller: 'common.views.modals.remarksModal as vm',
          backdrop: 'static',
          resolve: {
            title() {
              return App.localize('SpecialNotes');
            },
            existingRemarks() {
              return specialNotes;
            },
            remarksMaxLength() {
              return appConstants.serviceRequestMaxLength;
            },
          },
        })
        .result.then((data) => {
          hostEmployeeSvc
            .setEmployeeSpecialNotes({
              id: employeeId,
              specialNotes: data,
            })
            .success(() => {
              vm.getEmployees();
            });
        });
    }

    function createManualTransactionRequest(employee) {
      if (employee.hasViewMemberPermission) {
        redirect(employee.corporateCode, employee.nationalId);
      } else {
        hostEmployeeSvc
          .getEmployeeNationalId({
            employeeId: employee.id,
          })
          .success((data) => {
            redirect(employee.corporateCode, data);
          });
      }

      function redirect(corporateCode, nationalId) {
        $state.go('finance.createEditManualTransactionRequest', {
          isCreate: true,
          tenancyName: corporateCode,
          transactionTenantType: vm.enums.transactionTenantType.Member.id,
          nationalId,
        });
      }
    }

    function openInsuranceEndorsementModal(employee) {
      $uibModal.open({
        templateUrl: require('../../../common/views/modals/memberInsuranceEndorsement/memberInsuranceEndorsement.modal.html'),
        controller: 'common.views.member.insuranceEndorsementModal as vm',
        backdrop: 'static',
        resolve: {
          employeeId: employee.id,
          relationship: () => employee.relationship
        },
      });
    }

    function unflagInsuranceEndorsementFlaggedMember(employee) {
      if (!vm.permissions.canManageInsuranceEnrolmentFlag)
        return;
      
      vm.loading += 1;
      const updatedFlag = !employee.isInsuranceEnrolmentFlagged;

      hostEmployeeSvc
        .unflagMemberInsuranceEndorsementFlag({ 
          employeeId: employee.id, 
          isFlagged: updatedFlag
        })
        .success(() => {
          employee.isInsuranceEnrolmentFlagged = updatedFlag;
          abp.notify.info(
            App.localize('MemberInsuranceEnrolmentXUnflagged', employee.name)
          );
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }
  }
})();
