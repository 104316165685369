(() => {
  const module = angular.module('finance', ['ui.router']);

  module.config([
    '$stateProvider',
    function ($stateProvider) {
      $stateProvider.state('finance', {
        abstract: true,
        template: '<div ui-view class="ui-view-wrapper"></div>',
      });
      if (
        (App.isHost() || abp.setting.getBoolean('Hms.Feature.AccessFinanceTransactionData')) &&
        abp.auth.isGranted('HealthMetrics.Finance')
      ) {
        if (
          (App.isHost() || abp.setting.getBoolean('Hms.Feature.AccessFinanceTransactionData')) &&
          abp.auth.isGranted('HealthMetrics.Finance.SaleTransactions')
        ) {
          $stateProvider.state('finance.saleTransactions', {
            url:
              '/finance/saleTransactions?' +
              '{currencyCode:string}&' +
              '{countryCode:string}&' +
              '{accountOwnerId:string}&' +
              'billingAccountId&' +
              '{transactionTenantType:string}&' +
              '{category:string}&' +
              '{transactionType:int}&' +
              '{transactionNumber:string}&' +
              '{transactionStatus:int}&' +
              '{isPayments:bool}&' +
              '{paymentState:int}&' +
              'startDate&' +
              'endDate&' +
              '&sorting&{skipCount:int}&{maxResultCount:int}',
            templateUrl: require('./views/saleTransactions/saleTransactions.page.html'),
            dynamic: true,
            params: {
              skipCount: { value: 0 },
              maxResultCount: { value: module.consts.grid.defaultPageSize },
              isFromPayment: { value: null },
              tenancyName: { value: null },
            },
          });

          $stateProvider.state('finance.invoiceDetail', {
            url: '/finance/invoice/:transactionNumber',
            templateUrl: require('./views/saleTransactions/invoiceDetail/invoiceDetail.page.html'),
            params: {
              transactionNumber: {
                value: null,
              },
            },
          });

          $stateProvider.state('finance.creditMemoDetail', {
            url: '/finance/creditMemo/:transactionNumber',
            templateUrl: require('./views/saleTransactions/creditMemoDetail/creditMemoDetail.page.html'),
            params: {
              transactionNumber: {
                value: null,
              },
            },
          });

          $stateProvider.state('finance.myInvoisEInvoiceDetail', {
            url: '/finance/myInvoisEInvoice/:transactionNumber',
            templateUrl: require('./views/saleTransactions/myInvoisEInvoiceDetail/myInvoisEInvoiceDetail.page.html'),
            params: {
              transactionNumber: {
                value: null,
              },
            },
          });

          $stateProvider.state('finance.makePayment', {
            url: '/finance/makePayment',
            templateUrl: require('./views/saleTransactions/makePayment/makePayment.page.html'),
            params: {
              pageState: {
                value: null,
              },
            },
          });

          $stateProvider.state('finance.makePaymentFailed', {
            url: '/finance/makePayment/failed',
            templateUrl: require('./views/saleTransactions/makePayment/makePaymentFailed.page.html'),
          });

          $stateProvider.state('finance.makePaymentSuccess', {
            url: '/finance/makePayment/success/:orderId',
            templateUrl: require('./views/saleTransactions/makePayment/makePaymentSuccess.page.html'),
            params: {
              orderId: {
                value: null,
              },
            },
          });

          if (abp.auth.isGranted('HealthMetrics.Finance.SaleTransactions.CreditInvoice')) {
            $stateProvider.state('finance.applyCreditMemo', {
              url: '/finance/applyCreditMemo/:transactionNumber',
              templateUrl: require('./views/saleTransactions/applyCreditMemo/applyCreditMemo.page.html'),
              params: {
                transactionNumber: {
                  value: null,
                },
              },
            });
          }
        }

        if (abp.auth.isGranted('HealthMetrics.Finance.Payments.AccountReceivables')) {
          $stateProvider.state('finance.accountReceivables', {
            url:
              '/finance/accountReceivables?{activeView:int}&' +
              '{countryCode:string}&' +
              '{accountOwnerId:string}&' +
              'billingAccountId&' +
              'minAmount&' +
              'maxAmount&' +
              'virtualAccountNumber&' +
              '{transactionType:int}&' +
              '{status:int}&' +
              '{isPaymentHistoryTab:bool}&' +
              '&sorting&{skipCount:int}&{maxResultCount:int}',
            templateUrl: require('./views/accountReceivables/index.html'),
            dynamic: true,
            params: {
              skipCount: { value: 0 },
              maxResultCount: { value: module.consts.grid.defaultPageSize },
              activeView: {
                value: null,
              },
            },
          });
        }

        if (abp.auth.isGranted('HealthMetrics.Finance.Payments')) {
          $stateProvider.state('finance.paymentDetail', {
            url: '/finance/payment/:paymentNumber',
            templateUrl: require('./views/payments/financePaymentDetail.page.html'),
            params: {
              paymentNumber: {
                value: null,
              },
              isFromTransactionPage: {
                value: null,
              },
            },
          });
        }

        if (
          abp.auth.isGranted('HealthMetrics.Finance.Payments.Create') ||
          abp.auth.isGranted('HealthMetrics.Finance.Payments.Edit')
        ) {
          $stateProvider.state('finance.createEditPayment', {
            url: '/finance/payment/createEdit/:paymentNumber?{isEdit: bool}&{billingAccountId}&{bankAccountDepositId}&{invoiceNumber}&{isPayable: bool}&{isIndividual: bool}',
            templateUrl: require('./views/payments/createEditPayment/createEditPayment.page.html'),
            dynamic: true,
            params: {
              paymentNumber: {
                value: null,
              },
              isEdit: {
                value: false,
              },
              billingAccountId: {
                value: null,
              },
              bankAccountDepositId: {
                value: null,
              },
              invoiceNumber: {
                value: null,
              },
              isFromTransactionPage: {
                value: null,
              },
              isPayable: {
                value: null,
              },
              isIndividual: {
                value: null,
              },
            },
          });
        }

        if (abp.auth.isGranted('HealthMetrics.Finance.Settings')) {
          $stateProvider.state('finance.settings', {
            url: '/finance/settings',
            templateUrl: require('./views/settings/index.html'),
          });
        }

        if (abp.auth.isGranted('HealthMetrics.Finance.DebugTools')) {
          $stateProvider.state('finance.debugTools', {
            url: '/finance/debugTools',
            templateUrl: require('./views/debugTools/debugTools.page.html'),
          });
        }

        if (abp.auth.isGranted('HealthMetrics.Finance.AccountOwners')) {
          $stateProvider.state('finance.accountOwners', {
            url: '/finance/accountOwners',
            templateUrl: require('./views/accountOwners/index.html'),
          });

          $stateProvider.state('finance.manualTransactionSetup', {
            url: '/finance/accountOwners/manualTransactionSetup?{accountOwnerId:string}&{countryCode:string}',
            templateUrl: require('./views/accountOwners/manualTransactionSetup/manualTransactionSetup.page.html'),
          });
        }

        if (abp.auth.isGranted('HealthMetrics.Finance.BillingAccounts')) {
          $stateProvider.state('finance.billingAccounts', {
            url: '/finance/billingAccounts',
            templateUrl: require('./views/billingAccounts/billingAccounts.page.html'),
          });
          
          $stateProvider.state('finance.billingAccountDetail', {
            url: '/finance/billingAccounts/billingAccountDetail/:id',
            templateUrl: require('./views/billingAccounts/billingAccountDetail/billingAccountDetail.page.html'),
            params: {
              id: {
                value: null,
              },
            },
          });
        }

        if (abp.auth.isGranted('HealthMetrics.Finance.AccountPayables')) {
          if (abp.auth.isGranted('HealthMetrics.Finance.AccountPayables.PaymentInstructions')) {
            $stateProvider.state('finance.paymentInstructionBatch', {
              url:
                '/finance/paymentInstructionBatch?' +
                '{currencyCode:string}&' +
                '{countryCode:string}&' +
                '{accountOwnerId:string}&' +
                '{category:string}&' +
                '{batchNumber:string}&' +
                'startDate&' +
                'endDate&' +
                '&sorting&{skipCount:int}&{maxResultCount:int}',
              templateUrl: require('./views/accountPayables/paymentInstructionBatches.page.html'),
              dynamic: true,
              params: {
                skipCount: { value: 0 },
                maxResultCount: { value: module.consts.grid.defaultPageSize },
              },
            });

            $stateProvider.state('finance.paymentInstruction', {
              url: '/finance/paymentInstruction/:batchNumber?{paymentInstructionNumber:string}',
              templateUrl: require('./views/accountPayables/paymentInstruction.page.html'),
              dynamic: true,
              params: {
                batchNumber: {
                  value: null,
                },
              },
            });

            $stateProvider.state('finance.transferAdvice', {
              url: '/finance/transferAdvice/:batchNumber?{paymentInstructionNumber:string}',
              templateUrl: require('./views/accountPayables/transferAdvice.page.html'),
              dynamic: true,
              params: {
                batchNumber: {
                  value: null,
                },
              },
            });
          }
        }

        if (App.isHost()) {
          $stateProvider.state('finance.manualTransactionRequests', {
            url: '/finance/manualTransactionRequests',
            templateUrl: require('./views/manualTransactionRequests/index.html'),
          });
        }

        if (App.isHost()) {
          $stateProvider.state('finance.createEditManualTransactionRequest', {
            url: '/finance/manualTransactionRequests/createEditManualTransactionRequest?{requestId:string}&{isCreate:bool}',
            templateUrl: require('./views/manualTransactionRequests/createEditManualTransactionRequests/createEditManualTransactionRequest.page.html'),
            dynamic: true,
              params: {
                requestId: {
                  value: null,
                },
                isCreate: {
                  value: false,
                },
                tenancyName: {
                  value: null,
                },
                transactionTenantType: {
                  value: null,
                },
                nationalId: {
                  value: null,
                },
                isBilledPerSubsidiary: {
                  value: null,
                },
                subsidiaryId: {
                  value: null,
                },
              },
          })
        }

        $stateProvider.state('finance.myInvoisSandbox', {
          url: '/finance/myInvois/sandbox?sorting&{skipCount:int}&{maxResultCount:int}',
          templateUrl: require('./views/myInvois/sandbox.html'),
          dynamic: true,
          params: {
            skipCount: { value: 0 },
            maxResultCount: { value: module.consts.grid.defaultPageSize }
          }
        });

        $stateProvider.state('finance.myInvois', {
          url: '/finance/myInvois',
          templateUrl: require('./views/myInvois/index.html'),
        });
      }
    },
  ]);
})();
