(function () {
    var controllerId = 'host.views.insurers.index';
    var app = angular.module('app');
    app.controller(controllerId, [
        '$scope', '$state', '$stateParams', '$uibModal', '$timeout', 'abp.services.app.insurer', 'abp.services.app.commonLookup', 'countries', 'Hms.Finance.TransactionTenantType',
        function ($scope, $state, $stateParams, $modal, $timeout, insurerSvc, commonLookupSvc, countries, enumTransactionTenantType) {
            var vm = this;
            vm.loading = 0;
            vm.countryCode = null;
            vm.permissions = {
                create: abp.auth.isGranted('Host.NewInsurers.Create'),
                createManualTransaction: abp.auth.isGranted('HealthMetrics.Finance.ManualTransactionRequests.Create')
            };
            vm.enums = {
                transactionTenantType: enumTransactionTenantType,
              };

            vm.createManualTransactionRequest = createManualTransactionRequest;

            function init() {
                $timeout(function () {
                    vm.insurerGridOptions.paginationCurrentPage = Math.floor(viewOptions.skipCount / viewOptions.maxResultCount + 1);
                    getEligibleCountries();
                });
            }

            // Define functions

            vm.createInsurer = function () {
                $state.go('host.createEditInsurer');
            };

            vm.deleteInsurer = function (insurer) {
                abp.message.confirm(App.localize("InsurerDeleteWarningMessage", insurer.name), App.localize('AreYouSure'), function (result) {
                    vm.loading++;
                    if (result) {
                        insurerSvc.deleteInsurer({
                            id: insurer.id
                        }).success(function () {
                            vm.getInsurers();
                            abp.notify.success(App.localize("SuccessfullyDeleted"));
                        });
                    }
                    vm.loading--;
                }, {dangerMode: true});
            };

            // New insurer module

            // New insurer grid options

            var viewOptions = {
                skipCount: $stateParams.skipCount || 0,
                maxResultCount: $stateParams.maxResultCount || app.consts.grid.defaultPageSize,
                sorting: $stateParams.sorting
            };

            vm.insurerGridOptions = {
                appScopeProvider: vm,
                paginationPageSizes: app.consts.grid.defaultPageSizes,
                paginationPageSize: app.consts.grid.defaultPageSize,
                paginationCurrentPage: 0,
                useExternalPagination: true,
                useExternalSorting: true,
                enableRowSelection: true,
                enableSelectAll: false,
                enableFullRowSelect: true,
                enableRowHeaderSelection: false,
                multiSelect: false,
                columnDefs: [{
                    enableSorting: false,
                    name: App.localize('Actions'),
                    width: 100,
                    headerCellTemplate: "<span></span>",
                    cellTemplate: 'actionTemplate'
                }, {
                    displayName: App.localize('InsurerName'),
                    field: 'name',
                    minWidth: 150,
                    cellTemplate: "nameTemplate"
                }, {
                    displayName: App.localize('AccountOwnerName'),
                    field: 'accountOwnerName',
                    minWidth: 150
                }, {
                    displayName: App.localize('Country'),
                    field: 'countryCode',
                    width: 30,
                    visible: vm.hasRegionalSystemSupport,
                    cellTemplate: "countryTemplate"
                }, {
                    displayName: App.localize('BusinessRegistrationNumber'),
                    field: 'businessRegistrationNumber',
                    minWidth: 80
                }, {
                    displayName: App.localize('BusinessLocationAddress'),
                    field: 'address',
                    minWidth: 80
                }, {
                    displayName: App.localize('BillingAddress'),
                    field: 'billingAddress',
                    minWidth: 80,
                }, {
                    displayName: App.localize('VirtualAccountNumber'),
                    field: 'virtualAccountNumber',
                    minWidth: 80,
                }],
                onRegisterApi: function (gridApi) {
                    $scope.gridApi = gridApi;
                    $scope.gridApi.core.on.sortChanged($scope, function (scope, callback) {
                        var sorting = '';
                        for (var i = 0; i < callback.length; i++) {
                            if (i) sorting += ', ';
                            sorting += callback[i].field + ' ' + callback[i].sort.direction;
                        }
                        viewOptions.sorting = sorting;
                        vm.getInsurers();
                    });
                    gridApi.pagination.on.paginationChanged($scope, App.setPagination(viewOptions, vm.getInsurers));
                    gridApi.selection.on.rowSelectionChanged($scope, function (row, evt) {
                        if (row.isSelected) {
                            vm.gridSelectedId = row.entity.id;
                            $state.go('host.insurers.insurerTeams', {
                                insurerId: vm.gridSelectedId
                            });
                        } else {
                            $state.go('host.insurers');
                            vm.gridSelectedId = null;
                        }
                    })
                },
                data: []
            };

            vm.getInsurers = function () {
                if (vm.loading) return;
                vm.loading++;
                insurerSvc.getInsurers({
                    skipCount: viewOptions.skipCount,
                    maxResultCount: viewOptions.maxResultCount,
                    sorting: viewOptions.sorting,
                    countryCode: vm.countryCode
                }).success(function (data) {
                    vm.insurerGridOptions.data = data.items;
                    vm.insurerGridOptions.totalItems = data.totalCount;
                }).finally(function () {
                    vm.loading--;
                });
            };

            vm.refreshInsurers = function () {
                if (vm.loading) return;

                vm.getInsurers();
            };

            vm.updateLogo = function (insurer) {
                $modal.open({
                    templateUrl: require('/App/host/views/insurers/updateLogoModal.html'),
                    controller: 'host.views.insurers.updateLogoModal as vm',
                    backdrop: 'static',
                    resolve: {
                        insurerId: function () {
                            return insurer.id;
                        }
                    }
                }).result.then(function () {
                    vm.getInsurers();
                });
            };

            function getEligibleCountries() {
                vm.loading++;
                commonLookupSvc.getEligibleCountries().success(function (data) {
                    vm.countries = data;
                }).finally(function () {
                    vm.loading--;
                });
            }

            function createManualTransactionRequest(insurer) {
                $state.go('finance.createEditManualTransactionRequest', {
                  isCreate: true,
                  tenancyName: insurer.tenancyName,
                  transactionTenantType: vm.enums.transactionTenantType.Insurer.id,
                });
            }

            init();
        }
    ]);
})();
