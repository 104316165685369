(() => {
  angular
    .module('app')
    .controller(
      'finance.views.saleTransactions.makePaymentSuccess.page',
      MakePaymentSuccessController
    );

  MakePaymentSuccessController.$inject = [
    '$stateParams',
    'abp.services.finance.curlec',
  ];

  function MakePaymentSuccessController($stateParams, curlecSvc) {
    const vm = this;

    vm.loading = 0;
    vm.totalAmount = 0;
    vm.orderId = $stateParams.orderId;

    init();

    function init() {
      getOrderDetails();
    }

    function calculateTotalAmount(items) {
      return items.reduce((total, record) => total + record.paymentAmount, 0);
    }

    function getOrderDetails() {
      vm.loading += 1;
      curlecSvc
        .getOrderDetails({
          orderId: vm.orderId,
        })
        .success((data) => {
          vm.invoicePayments = data.invoicePayments;
          vm.currencyCode = data.currencyCode;
          vm.totalAmount = calculateTotalAmount(vm.invoicePayments);
        })
        .finally(() => {
          vm.loading -= 1;
        })
    }
  }
})();
