import swal from 'sweetalert';

(() => {
  angular
    .module('app')
    .controller('common.views.profile.manageTwoFactorAuth', ManageTwoFactorAuthController);

  ManageTwoFactorAuthController.$inject = [
    'authentication',
    '$uibModal',
    'abp.services.app.profile',
    'Hms.Users.User',
  ];

  function ManageTwoFactorAuthController(authentication, $modal, profileSvc, constsUser) {
    const vm = this;
    vm.constsUser = constsUser;
    vm.validatingOTP = false;
    vm.otpExpired = false;
    vm.remainingTime = 300; // in seconds

    vm.getUserAccountSettings = getUserAccountSettings;
    vm.updateTwoFactorAuth = updateTwoFactorAuth;
    vm.validateOTP = validateOTP;
    vm.resendOTP = resendOTP;
    vm.startCountdown = startCountdown;
    vm.disableResendButton = disableResendButton;

    vm.otpAttemptCounter = 0;
    vm.disableConfirm = false;

    init();

    function init() {
      vm.getUserAccountSettings();
    }

    function getUserAccountSettings() {
      vm.loading += 1;
      profileSvc
        .getUserAccountSettings()
        .success((data) => {
          vm.userAccountSettings = data;
          vm.emailAddress = data.emailAddress;
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function updateTwoFactorAuth(isEnable) {
      $modal
        .open({
          templateUrl: require('./updateTwoFactorAuthentication.modal.html'),
          controller: 'common.views.manageTwoFactorAuth.updateTwoFactorAuthenticationModal as vm',
          backdrop: 'static',
          resolve: {
            isEnable,
            emailAddress() {
              return vm.emailAddress;
            },
          },
        })
        .result.then((data) => {
          vm.validatingOTP = data;
          vm.startCountdown();
          vm.disableResendButton(60);
          profileSvc.sendValidationCodeAsync();
        });
    }

    function validateOTP() {
      if (vm.otpCode === undefined
          || vm.otpCode.length !== vm.constsUser.OTPLength) {
        showError();
        return;
      }

      const title = vm.userAccountSettings.twoFactorAuthentication
        ? 'TwoFactorAuthenticationDisabled'
        : 'TwoFactorAuthenticationEnabled';
      const description = vm.userAccountSettings.twoFactorAuthentication
        ? 'TwoFactorAuthenticationDisabledDescription'
        : 'TwoFactorAuthenticationEnabledDescription';

      profileSvc
        .validateOTP({
          code: vm.otpCode,
          enableTwoFA: !vm.userAccountSettings.twoFactorAuthentication,
          isFirstTime: vm.otpAttemptCounter < 1,
        })
        .success((data) => {
          if (data === true) {
            swal(
              {
                title: App.localize(title),
                text: App.localize(description),
                type: 'success',
                showConfirmButton: true,
                confirmButtonText: App.localize('Ok'),
                confirmButtonColor: '#C1C1C1',
                html: true,
                closeOnConfirm: true,
              },
              () => {
                swal.close();
                authentication.logout();
              }
            );
            vm.otpAttemptCounter = 0;
          } else {
            showError();
          }
        });
    }

    function showError() {
      vm.otpAttemptCounter += 1;

      if (vm.otpAttemptCounter >= vm.constsUser.MaxFailedTwoFactorAuthenticationAttempts)
        expireCode();

      swal(
        {
          title: App.localize('InvalidOTPTitle'),
          text: App.localize('InvalidOTPDescription'),
          type: 'warning',
          showConfirmButton: true,
          confirmButtonText: App.localize('Ok'),
          confirmButtonColor: '#C1C1C1',
          html: true,
          closeOnConfirm: true,
        },
        () => {
          swal.close();
        }
      );
    }

    let resendInterval = null;
    let remainingDisableTime = 0;

    function resendOTP() {

      if (vm.otpAttemptCounter >= vm.constsUser.MaxFailedTwoFactorAuthenticationAttempts)
        vm.otpAttemptCounter = 0;

      profileSvc.sendValidationCodeAsync()
        .success((getNewCode) => {
          if (getNewCode.result) {
            vm.otpExpired = true;
          }
        });

      if (vm.otpExpired) {
        vm.otpExpired = false;
        vm.remainingTime = 300;
        vm.startCountdown();
      }

      vm.disableResendButton(60);
      vm.disableConfirm = false;
    }

    function updateResendTimer() {
      vm.resendTimer = `(${remainingDisableTime}s)`;

      if (remainingDisableTime > 0) {
        remainingDisableTime -= 1;
      } else {
        vm.resendTimer = '';
        vm.resendCooldown = false;
      }
    }

    function expireCode() {
      vm.disableConfirm = true;
      
      vm.remainingTime = 0;
      vm.timer = 'Code expired';
      vm.otpExpired = true;

      clearInterval(resendInterval);

      remainingDisableTime = 0;
      updateResendTimer();
    }

    function startCountdown() {
      function updateTimer() {
        const minutes = Math.floor(vm.remainingTime / 60);
        const seconds = vm.remainingTime % 60;
        vm.timer = `Code expires in ${minutes}:${seconds.toString().padStart(2, '0')}`;

        if (vm.remainingTime > 0) {
          vm.remainingTime -= 1;
          setTimeout(updateTimer, 1000);
        } else {
          vm.timer = 'Code expired';
          vm.otpExpired = true;
        }
      }

      updateTimer();
    }

    function disableResendButton(durationInSeconds) {
      vm.resendCooldown = true;
      vm.resendTimer = durationInSeconds; // Show countdown

      remainingDisableTime = durationInSeconds;

      resendInterval = setInterval(() => updateResendTimer(), 1000);

      updateResendTimer();
    }
  }
})();
