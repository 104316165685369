(() => {
  angular
    .module('finance')
    .controller(
      'finance.views.billingAccounts.editAccountCodeModal',
      EditAccountCodeModalController
    );

  EditAccountCodeModalController.$inject = [
    '$uibModalInstance',
    'abp.service.finance.billingAccount',
    'billingAccount',
  ];

  function EditAccountCodeModalController($uibModalInstance, billingAccountSvc, billingAccount) {
    const vm = this;

    vm.update = update;
    vm.cancel = cancel;
    vm.billingAccountId = billingAccount.id;
    vm.billingAccountCode = billingAccount.accountCode;

    function update() {
      if (!vm.billingAccountCode) {
        abp.message.error(App.localize('InvalidBillingAccountCode'));
        return;
      }

      vm.saving += 1;
      billingAccountSvc
        .updateAccountCode({
          id: vm.billingAccountId,
          accountCode: vm.billingAccountCode,
        })
        .success(() => {
          billingAccount.accountCode = vm.billingAccountCode;
          abp.notify.success(App.localize('SuccessfullyUpdated'));
          $uibModalInstance.close(vm.billingAccountCode);
        })
        .finally(() => {
          vm.saving -= 1;
        });
    }

    function cancel() {
      $uibModalInstance.dismiss();
    }
  }
})();
