(() => {
  angular
    .module('app')
    .controller('finance.views.saleTransactions.makePayment.page', FinanceMakePaymentController);

    FinanceMakePaymentController.$inject = [
    '$state',
    '$stateParams',
    'abp.services.finance.commonLookup',
  ];

  function FinanceMakePaymentController($state, $stateParams, commonLookupSvc) {
    const vm = this;

    vm.pageState = null;
    vm.selectedInvoices = [];

    vm.calculateTotalAmount = calculateTotalAmount;

    init();

    function init() {
      getSettings();
    }

    function getSettings() {
      vm.loading += 1;
      commonLookupSvc
        .getCommonSettings()
        .success((data) => {
          if (data.curlecIntegration) {
            if ($stateParams.pageState !== null) {
              vm.pageState = $stateParams.pageState;
            } else {
              vm.pageState = 0;
            }
          } else {
            abp.message.warn('Cannot load because this feature is not enabled.');
          }
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function calculateTotalAmount(items) {
      return items.reduce((total, record) => total + record.outstandingAmount, 0);
    }
  }
})();
