(() => {
  angular
    .module('finance')
    .controller(
      'finance.views.saleTransactions.updatePaymentStatusModal',
      UpdatePaymentStatusModal
    );

  UpdatePaymentStatusModal.$inject = [
    '$uibModalInstance',
    'paymentNumber',
    'paymentId',
    'paymentStatus',
    'HealthMetrics.Finance.Payments.PaymentStatus',
    'abp.services.finance.payments',
  ];

  function UpdatePaymentStatusModal(
    $uibModalInstance,
    paymentNumber,
    paymentId,
    paymentStatus,
    enumPaymentStatus,
    paymentsSvc
  ) {
    const vm = this;

    vm.loading = 0;
    vm.paymentNumber = paymentNumber;
    vm.paymentId = paymentId;
    vm.paymentStatus = paymentStatus;
    vm.originalPaymentStatus = paymentStatus;
    vm.selectedPaymentStatus = paymentStatus;
    vm.paymentStatusRemarks = '';

    vm.enums = {
      paymentStatus: enumPaymentStatus,
    };

    vm.close = close;
    vm.save = save;

    function close() {
      $uibModalInstance.dismiss();
    }

    function save() {
      let hasError = false;

      hasError = vm.originalPaymentStatus === vm.selectedPaymentStatus;
      hasError = !vm.paymentStatusRemarks || vm.paymentStatusRemarks.trim() === '';

      if (!hasError) {
        let input = {
          paymentId: vm.paymentId,
          paymentStatus: vm.selectedPaymentStatus,
          paymentModificationRemarks: vm.paymentStatusRemarks.trim(),
        };

        vm.loading += 1;
        paymentsSvc
          .updatePaymentStatus(input)
          .success(() => {
            $uibModalInstance.close(true);
          })
          .finally(() => {
            vm.loading -= 1;
          });
      }
    }
  }
})();
