(() => {
  angular
    .module('app')
    .component('commonPostHospitalizationGuaranteeLettersDetailsRevisionsComponent', {
      templateUrl: require('./revisions.component.html'),
      controller: RevisionsComponentController,
      controllerAs: 'vm',
      bindings: {
        letterNumber: '<',
        timeZoneId: '<',
      },
    });

  RevisionsComponentController.$inject = [
    '$scope',
    '$timeout',
    'abp.services.app.postHospitalizationGuaranteeLetter',
    'Hms.ServiceRequests.ServiceRequestType',
    'appConstants',
  ];

  function RevisionsComponentController(
    $scope,
    $timeout,
    postHospitalizationGuaranteeLetterSvc,
    enumServiceRequestType,
    appConstants
  ) {
    const vm = this;
    vm.loading = 0;

    vm.enums = {};
    vm.enums.serviceRequestType = enumServiceRequestType;

    vm.permissions = {
      update: abp.auth.isGranted('GuaranteeLetters.Host.Update'),
      topUp: abp.auth.isGranted('GuaranteeLetters.Host.TopUp'),
      extend: abp.auth.isGranted('GuaranteeLetters.Host.Approve'),
      advance: abp.auth.isGranted('GuaranteeLetters.Corporate.Advance'),
    };

    const viewOptions = {
      maxResultCount: appConstants.grid.defaultPageSize,
      skipCount: 0,
      sorting: null,
    };

    vm.revisionComponentGridOptions = {
      appScopeProvider: vm,
      paginationPageSizes: appConstants.grid.defaultPageSizes,
      paginationPageSize: appConstants.grid.defaultPageSize,
      paginationCurrentPage: 0,
      useExternalPagination: true,
      useExternalSorting: true,
      resizable: true,
      columnDefs: [
        {
          name: 'Actions',
          displayName: 'Actions',
          enableSorting: false,
          width: 100,
          headerCellTemplate: '<span></span>',
          cellTemplate: 'revisionComponentViewTemplate',
          allowCellFocus: false,
        },
        {
          displayName: App.localize('RevisionType'),
          enableSorting: false,
          field: 'revisionType',
          minWidth: 120,
          cellTemplate: 'revisionTypeTemplate',
        },
        {
          displayName: App.localize('RevisionNumber'),
          enableSorting: false,
          field: 'revisionNumber',
          minWidth: 120,
        },
        {
          displayName: App.localize('ApprovalTime'),
          enableSorting: false,
          field: 'approvalTime',
          minWidth: 120,
          cellTemplate: 'approvalTimeTemplate',
        },
      ],
      onRegisterApi(gridApi) {
        $scope.gridApi = gridApi;

        $scope.gridApi.core.on.sortChanged($scope, (scope, callback) => {
          viewOptions.sorting =
            callback.length && callback[0].field
              ? `${callback[0].field} ${callback[0].sort.direction}`
              : null;
          getPostHospitalizationGuaranteeLetterRevision();
        });
        gridApi.pagination.on.paginationChanged(
          $scope,
          App.setPagination(viewOptions, getPostHospitalizationGuaranteeLetterRevision)
        );
      },
      data: [],
    };

    vm.getDateTimeString = getDateTimeString;

    vm.$onInit = init;

    function init() {
      $timeout(() => {
        vm.revisionComponentGridOptions.paginationCurrentPage = Math.floor(
          viewOptions.skipCount / viewOptions.maxResultCount + 1
        );
      });
    }

    function getDateTimeString(date, timeZoneId) {
      return App.getDateTimeString(date, timeZoneId);
    }

    function getPostHospitalizationGuaranteeLetterRevision() {
      vm.loading += 1;
      postHospitalizationGuaranteeLetterSvc
        .getPostHospitalizationGuaranteeLetterRevision({
          guaranteeLetterNumber: vm.letterNumber,
          skipCount: viewOptions.skipCount,
          maxResultCount: viewOptions.maxResultCount,
        })
        .success((data) => {
          vm.revisionComponentGridOptions.totalItems = data.totalCount;
          vm.revisionComponentGridOptions.data = data.items;
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }
  }
})();
