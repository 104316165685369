(() => {
  angular
    .module('app')
    .component('hostCorporatesMasterPoliciesMasterPolicyEligibilityComponent',{
      templateUrl: require('./eligibility.component.html'),
      controller: EligibilityController,
      controllerAs: 'vm',
      bindings: {
        data: '<',
        isCreate: '<',
        isRenewal: '<',
        isEdit: '<'
      },
      require: {
        container: '^hostCorporatesMasterPoliciesMasterPolicyContainerComponent'
      },
    });

  EligibilityController.$inject = ['$scope', '$timeout'];

  function EligibilityController($scope, $timeout) {
    const vm = this;
    vm.loading = 0;
    vm.openTab = openTab;
    vm.masterPolicy = vm.data.masterPolicy;

    function init() {
      $timeout(() => { angular.element('#defaultSpouse').trigger('click'); });
    }

    angular.element(document).ready(() => {
      init();
    });

    // #region Validation

    vm.$onInit = () => {
      vm.container.addWidget(this);
    };

    vm.isDirty = () =>
      $scope.corporateMasterPolicyCreateEditEligibilityForm 
      && $scope.corporateMasterPolicyCreateEditEligibilityForm.$dirty;

    vm.setPristine = () => {
      $scope.corporateMasterPolicyCreateEditEligibilityForm.$setPristine();
    };

    // Force validation on this form.
    // Return true if valid, otherwise, false.

    vm.validateForm = () => {
      const form = $scope.corporateMasterPolicyCreateEditEligibilityForm;
      App.touchFormErrors(form);
      return form.$valid;
    };

    // #endregion Validation

    function openTab(evt, tabname) {
      // Get all elements with class="tabcontent" and hide them

      $('.tabcontent').css('display', 'none');

      // Get all elements with class="tablinks" and remove the class "active"

      $('.tablinks').removeClass('active');

      // Show the current tab, and add an "active" class to the link that opened the tab

      $(`#${ tabname}`).css('display', 'block');
      evt.currentTarget.className += ' active';
    }
  }
})();
