import angular from 'angular';

(() => {
  angular
    .module('finance')
    .controller('finance.views.billingAccounts', BillingAccountsPageController);

  BillingAccountsPageController.$inject = [
    '$scope',
    '$state',
    '$stateParams',
    '$timeout',
    'moment',
    'financeConstants',
    'abp.services.finance.commonLookup',
    'Hms.Finance.KnownBillingAccountType',
    'Hms.Finance.KnownBillingAccountEntityTypes',
    'abp.service.finance.billingAccount',
  ];

  function BillingAccountsPageController(
    $scope,
    $state,
    $stateParams,
    $timeout,
    moment,
    financeConsts,
    commonLookupSvc,
    enumFinanceKnownBillingAccountType,
    enumFinanceKnownBillingAccountEntityTypes,
    billingAccountSvc
  ) {
    const vm = this;

    vm.loading = 0;

    vm.accountOwners = [];
    vm.billingAccounts = [];

    vm.categoryOption = [];

    vm.dateRangeModel = new DateRangeModel(
      $stateParams.startDate,
      $stateParams.endDate,
      getBillingAccounts
    );
    vm.requestParams = new RequestParamsModel($stateParams);

    vm.permissions = {
      createTenantlessBillingAccount: abp.auth.isGranted(
        'HealthMetrics.Finance.BillingAccounts.CreateTenantlessBillingAccount'
      ),
    };

    vm.enums = {
      knownBillingAccountType: enumFinanceKnownBillingAccountType,
      knownBillingAccountEntityTypes: enumFinanceKnownBillingAccountEntityTypes,
    };

    vm.gridOptions = {
      appScopeProvider: vm,
      paginationPageSizes: financeConsts.grid.defaultPageSizes,
      paginationPageSize: financeConsts.grid.defaultPageSize,
      paginationCurrentPage: 0,
      useExternalPagination: true,
      useExternalSorting: true,
      columnDefs: getGridOptionsColumnDefs(),
      onRegisterApi(gridApi) {
        $scope.gridApi = gridApi;
        gridApi.pagination.on.paginationChanged(
          $scope,
          App.setPagination(vm.requestParams, getBillingAccounts)
        );
      },
      data: [],
    };

    vm.getAccountOwners = getAccountOwners;
    vm.localizeEntityTypes = localizeEntityTypes;
    vm.getDateTimeString = getDateTimeString;
    vm.getBillingAccounts = getBillingAccounts;
    vm.getGridOptionsColumnDefs = getGridOptionsColumnDefs;

    init();

    // Init function.

    function init() {
      $timeout(() => {
        vm.gridOptions.paginationCurrentPage = Math.floor(
          vm.requestParams.skipCount / vm.requestParams.maxResultCount + 1
        );
      });
      getAccountOwners();
      localizeEntityTypes();
    }

    function getAccountOwners() {
      commonLookupSvc.getAccountOwners().success((data) => {
        if (data) {
          vm.accountOwners = data.items;
        }
      });
    }

    function localizeEntityTypes() {
      _.forEach(vm.enums.knownBillingAccountEntityTypes, (entityType) => {
        entityType.displayName = App.localize(entityType.name);
      })
    }

    function getDateTimeString(date, timeZoneId) {
      return App.getDateTimeString(date, timeZoneId);
    }

    function registerStateParams() {
      $state.transitionTo($state.current, vm.requestParams.toStateParams(), {
        notify: false,
      });
    }

    function getBillingAccounts() {
      registerStateParams();
      const input = vm.requestParams.toInputDto();

      vm.loading += 1;
      billingAccountSvc
        .getBillingAccounts(input)
        .success((data) => {
          vm.gridOptions.totalItems = data.totalCount;
          vm.gridOptions.data = data.items;

          // Localize entity type names for display.
          _.forEach(data.items, (billingAccounts) => {
            billingAccounts.typeDisplayName = App.localize(
              _.find(
                enumFinanceKnownBillingAccountEntityTypes,
                (entityType) => entityType.id === billingAccounts.entityType
              ).name
            );
          })
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    // Models

    function DateRangeModel(startDate, endDate, eventCallback) {
      const opt = App.createDateRangePickerOptions();
      this.options = opt;
      this.options.eventHandlers = {
        'apply.daterangepicker': () => {
          eventCallback();
        },
      };
    }

    function RequestParamsModel(params) {
      this.countryCode = params.IndividualNationalityCode || null;
      this.accountType = params.accountType || null;
      this.accountOwnerId = params.accountOwnerId || null;
      this.entityType = params.entityType || null;
      this.accountCode = params.accountCode || null;
      this.businessIdentifierCode = params.businessIdentifierCode || null;
      this.id = params.id || null;
      this.billingAccountName = params.Name || null;

      this.skipCount = params.skipCount || 0;
      this.maxResultCount = params.maxResultCount || financeConsts.grid.defaultPageSize;
      this.sorting = params.sorting;

      this.toInputDto = () => {
        const input = $.extend({}, this);

        if (vm.dateRangeModel.startDate)
          input.startDate = moment(vm.dateRangeModel.startDate).toJSON();
        if (vm.dateRangeModel.endDate) input.endDate = moment(vm.dateRangeModel.endDate).toJSON();
        return input;
      };

      this.toStateParams = () => {
        const startDate = vm.dateRangeModel.startDate
          ? vm.dateRangeModel.startDate.format('YYYY-MM-DD')
          : null;
        const endDate = vm.dateRangeModel.endDate
          ? vm.dateRangeModel.endDate.format('YYYY-MM-DD')
          : null;

        const stateParams = {
          countryCode: this.countryCode,
          accountType: this.accountType,
          accountOwnerId: this.accountOwnerId,
          entityType: this.entityType,
          accountCode: this.accountCode,
          businessIdentifierCode: this.businessIdentifierCode,
          id: this.id,

          startDate,
          endDate,

          skipCount: this.skipCount,
          maxResultCount: this.maxResultCount,
          sorting: this.sorting,
        };
        return stateParams;
      };
    }

    // Column defintions

    function getGridOptionsColumnDefs() {
      const columnDefs = [
        {
          name: App.localize('Actions'),
          enableSorting: false,
          width: 100,
          headerCellTemplate: '<span></span>',
          cellTemplate: 'actionTemplate',
        },
        {
          displayName: App.localize('Country'),
          enableSorting: false,
          field: 'countryCode',
          width: 30,
          visible: vm.isHost,
          cellTemplate: 'countryTemplate',
        },
        {
          displayName: App.localize('BillingAccountName'),
          enableSorting: false,
          field: 'name',
          minWidth: 100,
        },
        {
          displayName: App.localize('AccountCode'),
          enableSorting: false,
          field: 'accountCode',
          minWidth: 100,
        },
        {
          displayName: App.localize('EntityType'),
          enableSorting: false,
          field: 'typeDisplayName',
          minWidth: 100,
        },
        {
          displayName: App.localize('AccountType'),
          enableSorting: false,
          field: 'type',
          minWidth: 100,
        },
        {
          displayName: App.localize('Tenant'),
          enableSorting: false,
          field: 'businessIdentifierCode',
          cellTemplate: 'billingAccountTemplate',
          minWidth: 100,
        },
        {
          displayName: App.localize('CreationTime'),
          enableSorting: false,
          field: 'creationTimeOffset',
          cellTemplate: 'creationTimeTemplate',
          minWidth: 100,
        },
      ];

      return columnDefs;
    }
  }
})();