(() => {
  angular
    .module('app')
    .directive('waitingPeriodCoverageInfo', WaitingPeriodCoverageInfo)
    .controller(
      'common.views.member.insuranceEndorsementModal',
      InsuranceEndorsementModalController
    );

  InsuranceEndorsementModalController.$inject = [
    '$uibModalInstance',
    'abp.services.app.hostEmployee',
    'moment',
    'employeeId',
    'relationship',
  ];

  function InsuranceEndorsementModalController(
    $uibModalInstance,
    hostEmployeeSvc,
    moment,
    employeeId,
    relationship
  ) {
    const vm = this;
    vm.loading = 0;
    vm.confirm = confirm;
    vm.cancel = cancel;
    vm.employeeId = employeeId;
    vm.memberInformation = null;

    init();

    function init() {
      if (vm.loading) return;

      vm.loading += 1;
      hostEmployeeSvc
        .getMemberInsuranceEndorsementInformation({ id: employeeId })
        .success((response) => {
          vm.memberInformation = response;
          vm.memberInformation.ageEligibilityRules = getAgeEligibilityRules(
            response.masterPolicySettings.enrolmentRules
          );
          vm.memberInformation.processedCoverageDays = getProcessedCoverageDays(
            response.hospitalizationEffectiveDate,
            response.hospitalizationSetting
          );
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function getAgeEligibilityRules(enrolmentRules) {
      return enrolmentRules.find((x) => x.relationship === relationship);
    }

    function getProcessedCoverageDays(enrolmentDate, hospitalizationSetting) {
      const todayDate = moment().startOf('day');

      const getCoverageDays = (waitingPeriodDays) => {
        const coverageStartDate = moment(enrolmentDate)
          .subtract(1, 'days') // inclusive of enrolment start date
          .startOf('day')
          .add(waitingPeriodDays, 'days');

        return todayDate.diff(coverageStartDate, 'days');
      };

      const result = {
        admissionWaitingAccidentDays: hospitalizationSetting.admissionWaitingAccidentDays,
        admissionWaitingAccidentCoverageDays: getCoverageDays(
          hospitalizationSetting.admissionWaitingAccidentDays
        ),

        admissionWaitingIllnessDays: hospitalizationSetting.admissionWaitingIllnessDays,
        admissionWaitingIllnessCoverageDays: getCoverageDays(
          hospitalizationSetting.admissionWaitingIllnessDays
        ),

        admissionWaitingNonAccidentalIllnessDays:
          hospitalizationSetting.admissionWaitingNonAccidentalIllnessDays,
        admissionWaitingNonAccidentalIllnessCoverageDays: getCoverageDays(
          hospitalizationSetting.admissionWaitingNonAccidentalIllnessDays
        ),

        admissionWaitingPreExistingDays: hospitalizationSetting.admissionWaitingPreExistingDays,
        admissionWaitingPreExistingCoverageDays: getCoverageDays(
          hospitalizationSetting.admissionWaitingPreExistingDays
        ),
      };

      return result;
    }

    function confirm() {
      $uibModalInstance.close({
        confirm: true,
      });
    }

    function cancel() {
      $uibModalInstance.dismiss();
    }
  }

  function WaitingPeriodCoverageInfo() {
    return {
      restrict: 'E',
      scope: {
        waitingPeriodDays: '<',
        coverageDays: '<',
      },
      templateUrl: 'waitingPeriodCoverageInfoTemplate',
    };
  }
})();
