(() => {
  angular
    .module('app')
    .controller(
      'finance.views.saleTransactions.makePaymentFailed.page',
      MakePaymentFailedController
    );

  MakePaymentFailedController.$inject = ['$stateParams'];

  function MakePaymentFailedController($stateParams) {
    const vm = this;

    vm.loading = 0;
    vm.currencyCode = 'MYR';

    vm.returnToCheckout = returnToCheckout;

    init();

    function init() {}

    function returnToCheckout() {}
  }
})();
