/* eslint-disable no-bitwise */
(() => {
  angular
    .module('app')
    .controller(
      'corporate.views.benefitGroups.createOrEditBenefitGroup',
      BenefitGroupCreateEditController
    );

  BenefitGroupCreateEditController.$inject = [
    '$scope',
    '$filter',
    '$state',
    '$stateParams',
    '$uibModal',
    'abp.services.app.benefitGroup',
    'abp.services.app.commonLookup',
    'abp.services.app.tenant',
    'moment',
  ];
  function BenefitGroupCreateEditController(
    $scope,
    $filter,
    $state,
    $stateParams,
    $modal,
    benefitGroupSvc,
    commonLookupSvc,
    tenantSvc,
    moment
  ) {
    const vm = this;

    const ccyFilter = $filter('currencyFormat');

    const benefitGroupId = $stateParams.id;
    const isPreEmploymentBenefit = $stateParams.isPreEmployment;
    const isCopyAction = $stateParams.isCopy;

    vm.loading = 0;
    vm.saving = false;
    vm.benefitGroup = null;
    vm.treatmentEditData = null;
    vm.packages = null;
    vm.isPreEmployment = isPreEmploymentBenefit;
    vm.hasMobileApp = !isPreEmploymentBenefit && abp.features.isEnabled('HasMobileApp');
    vm.supportPlans = [];
    vm.hasSupportPlans = false;
    vm.isAccessibleTreatmentsDirty = false;
    vm.isSpecialistTreatmentsDirty = false;
    vm.isPharmacyDirty = false;
    vm.countryCode = null;
    vm.currencyCode = null;
    vm.activeServices = [];

    vm.hasSpecialist = abp.features.isEnabled('Specialist.Enabled');
    vm.hasPharmacy = abp.features.isEnabled('Pharmacy.Enabled');
    vm.hasHealthScreening = false;
    vm.corporateHasMobileNpr = vm.hasMobileApp && abp.features.isEnabled('HasMobileNpr');
    vm.hasCopay = abp.features.isEnabled('HasCopay');
    vm.hasRegionalSystemSupport = abp.setting.getBoolean('Hms.Feature.RegionalSystemSupport');
    vm.applicationHasHealthScreeningModule = abp.setting.getBoolean(
      'Hms.Feature.HealthScreeningModule'
    );

    vm.beneficiary = {
      principal: 1,
      dependent: 2,
    };

    vm.summernoteOptions = {
      placeholder: App.localize('RichTextEditorPlaceholder'),
      height: 300,
      focus: true,
      airMode: true,
      toolbar: [
        ['edit', ['undo', 'redo']],
        ['headline', ['style']],
        [
          'style',
          ['bold', 'italic', 'underline', 'superscript', 'subscript', 'strikethrough', 'clear'],
        ],
        ['fontface', ['fontname']],
        ['textsize', ['fontsize']],
        ['fontclr', ['color']],
        ['alignment', ['ul', 'ol', 'paragraph', 'lineheight']],
        ['height', ['height']],
        ['table', ['table']],
        ['view', ['fullscreen', 'codeview']],
      ],
    };

    vm.save = save;

    function init() {
      getTenantActiveServices();
    }

    function getBenefitGroupForEdit() {
      benefitGroupSvc
        .getBenefitGroupForEdit({
          id: benefitGroupId,
          isPreEmploymentBenefitGroup: isPreEmploymentBenefit,
          countryCode: vm.countryCode,
        })
        .success((data) => {
          if (isCopyAction) {
            data.benefitGroup.id = 0;
            data.benefitGroup.name = null;
          }

          vm.hasHealthScreening = data.hasHealthScreeningService;

          vm.benefitGroup = new BenefitGroupModel(data.benefitGroup);
          vm.preChangeBenefitGroup = new BenefitGroupModel(data.benefitGroup);

          vm.hasDependentsOnlyModifier = false;

          _.forIn(vm.benefitGroup.modifiers, (modifier) => {
            if (modifier.beneficiary === 2) vm.hasDependentsOnlyModifier = true;
          });

          vm.benefitGroup.modificationLog = data.modificationLog;

          vm.treatmentEditData = data.general;
          vm.preChangeTreatmentEditData = angular.copy(data.general);

          vm.specialistTreatmentEditData = data.specialist;
          vm.preChangeSpecialistTreatmentEditData = angular.copy(data.specialist);

          vm.pharmacyTreatmentEditData = data.pharmacy;
          vm.preChangePharmacyTreatmentEditData = angular.copy(data.pharmacy);

          vm.healthScreeningTreatmentEditData = data.healthScreening;
          vm.preChangeHealthScreeningTreatmentEditData = angular.copy(data.healthScreening);

          vm.packages = data.packages;
          if (data.supportPlans && data.supportPlans.length) {
            vm.supportPlans = data.supportPlans;
            vm.supportPlans.splice(0, 0, {
              name: App.localize('None'),
              value: null,
            });
            if (!data.hasInpatientModule) vm.hasSupportPlans = true;
          }
        })
        .finally();
    }

    function getCountryAndCurrencyCodes() {
      vm.loading += 1;
      commonLookupSvc
        .getTenantCountryCode()
        .success((data) => {
          vm.countryCode = data;
          getBenefitGroupForEdit();
        })
        .finally(() => {
          if (vm.hasRegionalSystemSupport) {
            commonLookupSvc
              .getTenantCurrencyCode()
              .success((data) => {
                vm.currencyCode = data;
              })
              .finally(() => {
                vm.coPayExample = App.localize('CoPayExample', vm.currencyCode);
              });
          } else {
            vm.currencyCode = abp.setting.get('Hms.General.CurrencyCode');
            vm.coPayExample = App.localize('CoPayExample', vm.currencyCode);
          }

          vm.loading -= 1;
        });
    }

    function buildTreatmentTypeList(customTreatmentTypes, benefits) {
      const types = [];
      let grantedBenefits = benefits;

      if (!grantedBenefits) grantedBenefits = [];

      vm.activeServices.forEach((type) => {
        switch (type.value) {
          case 1:
            types.push(
              new TreatmentModel(
                1,
                App.localize('GP'),
                'fas fa-stethoscope',
                _.indexOf(grantedBenefits, 1) >= 0
              )
            );
            break;
          case 2:
            types.push(
              new TreatmentModel(
                2,
                App.localize('Dental'),
                'mdi mdi-tooth',
                _.indexOf(grantedBenefits, 2) >= 0
              )
            );
            break;
          case 3:
            types.push(
              new TreatmentModel(
                3,
                App.localize('Optical'),
                'mdi mdi-sunglasses',
                _.indexOf(grantedBenefits, 3) >= 0
              )
            );
            break;
          case 5:
            types.push(
              new TreatmentModel(
                5,
                App.localize('Specialist'),
                'fas fa-user-md',
                _.indexOf(grantedBenefits, 5) >= 0
              )
            );
            break;
          case 6:
            types.push(
              new TreatmentModel(
                6,
                App.localize('Pharmacy'),
                'fas fa-capsules',
                _.indexOf(grantedBenefits, 6) >= 0
              )
            );
            break;
          case 9:
            types.push(
              new TreatmentModel(
                9,
                App.localize('HealthScreening'),
                'fas fa-heartbeat',
                _.indexOf(grantedBenefits, 9) >= 0
              )
            );
            break;
          default:
            break;
        }
      });

      _.each(customTreatmentTypes, (ct) => {
        types.push(
          new TreatmentModel(
            ct.value,
            ct.name,
            'far fa-copy',
            _.indexOf(grantedBenefits, ct.value) >= 0
          )
        );
      });

      return types;
    }

    function automap(source, target) {
      _.forIn(source, (v, k) => {
        target[k] = source[k];
      });
    }

    function getTenantActiveServices() {
      vm.activeServices = [];

      vm.loading += 1;
      tenantSvc
        .getTenantActiveServices({})
        .success((data) => {
          vm.activeServices = data;
          getCountryAndCurrencyCodes();
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    /* Types */

    function BenefitGroupModel(dto) {
      const that = this;

      // Basic properties

      that.id = 0;
      that.name = '';
      that.supportPlanId = null;
      that.isPreEmploymentBenefitGroup = false;
      that.coverSpecialist = false;
      that.coverPharmacy = false;
      that.claimPolicy = {};
      that.coverHealthScreening = false;

      // Pre-employment package

      that.preEmploymentPackage = [];

      // Benefits

      that.hasDependentCovered = 1;
      that.principalBenefitsServiceType = [];
      that.principalBypassTreatmentMaxRate = false;
      that.principalGuaranteeLetterSources = 0;
      that.dependentBenefitsServiceType = [];
      that.dependentBypassTreatmentMaxRate = false;
      that.dependentGuaranteeLetterSources = 0;
      that.medicationAllowanceInDays = null;
      that.dependentMedicationAllowanceInDays = null;
      that.referralValidityDaysLimit = null;
      that.dependentReferralValidityDaysLimit = null;
      that.appoitnmentCardValidityDaysLimit = null;
      that.dependentAppointmentCardValidityDaysLimit = null;

      // Benefit Modifiers

      that.modifiers = [];

      // Co pay

      that.coPays = [];

      // Mobile properties

      that.mobileShowBenefitSummary = false;
      that.mobileShowRemainingBalance = false;
      that.mobileShowMcRemaining = false;
      that.mobileShowMcTaken = false;
      that.hasMobileNpr = false;

      // Benefit summaries

      that.principalSummaries = [];
      that.principalSummariesServiceType = [];
      that.showPrincipalFootnote = false;
      that.dependentSummaries = [];
      that.dependentSummariesServiceType = [];
      that.showDependentFootnote = false;
      that.showDependentFootnoteServiceType = false;

      // Functions

      that.addModifier = function addModifier() {
        const modifier = new Modifier(that);
        vm.benefitGroup.modifiers.push(modifier);
      };

      that.removeModifier = function removeModifer(index) {
        return new Promise((resolve) => {
          abp.message
            .confirm(
              App.localize('DeleteBenefitModifierWarningMessage'),
              App.localize('AreYouSure'),
              (d) => {
                if (d) {
                  $scope.$apply(() => {
                    vm.benefitGroup.modifiers.splice(index, 1);
                    vm.benefitsForm.$setDirty();
                    that.refreshResult();
                  });
                  return true;
                }

                return false;
              }
            )
            .then((res) => resolve(res));
        });
      };

      that.addCoPay = function addCopay() {
        const coPay = new CoPay(that);
        vm.benefitGroup.coPays.push(coPay);
      };

      that.removeCoPay = (index) => {
        abp.message.confirm(
          App.localize('DeleteBenefitModifierWarningMessage'),
          App.localize('AreYouSure'),
          (d) => {
            if (d) {
              $scope.$apply(() => {
                vm.benefitGroup.coPays.splice(index, 1);
                vm.copayForm.$setDirty();
                that.refreshResult();
              });
              return true;
            }

            return false;
          }
        );
      };

      that.benefitChangedServiceType = function benefitChangedServiceType() {
        _.each(that.modifiers, (m) => {
          m.refreshTreatmentValidityServiceType();
        });
        _.each(that.coPays, (m) => {
          m.refreshTreatmentValidityServiceType();
        });
        that.refreshResult();
      };

      that.toDto = function toDto() {
        function getBenefitServiceTypeIds(benefits) {
          return _(benefits).filter('valid').map('id').value();
        }

        function getModifierDto(modifiers) {
          return _(modifiers)
            .map((m) => m.toDto())
            .value();
        }

        function getCoPayDto(coPays) {
          return _(coPays)
            .map((m) => m.toDto())
            .value();
        }

        return {
          id: that.id,
          name: that.name,
          supportPlanId: that.supportPlanId,
          isPreEmploymentBenefitGroup: that.isPreEmploymentBenefitGroup,
          preEmploymentPackage: that.preEmploymentPackage,
          hasDependentCovered: that.hasDependentCovered,
          principalBenefitsServiceType: getBenefitServiceTypeIds(that.principalBenefitsServiceType),
          principalBypassTreatmentMaxRate: that.principalBypassTreatmentMaxRate,
          principalGuaranteeLetterSources: that.principalGuaranteeLetterSources.toDto(),
          dependentBenefitsServiceType: that.hasDependentCovered
            ? getBenefitServiceTypeIds(that.dependentBenefitsServiceType)
            : [],
          dependentBypassTreatmentMaxRate: that.hasDependentCovered
            ? that.dependentBypassTreatmentMaxRate
            : false,
          dependentGuaranteeLetterSources: that.dependentGuaranteeLetterSources.toDto(),
          medicationAllowanceInDays: that.medicationAllowanceInDays,
          dependentMedicationAllowanceInDays: that.dependentMedicationAllowanceInDays,
          referralValidityDaysLimit: that.referralValidityDaysLimit,
          dependentReferralValidityDaysLimit: that.dependentReferralValidityDaysLimit,
          appointmentCardValidityDaysLimit: that.appointmentCardValidityDaysLimit,
          dependentAppointmentCardValidityDaysLimit: that.dependentAppointmentCardValidityDaysLimit,
          modifiers: getModifierDto(that.modifiers),
          coPays: getCoPayDto(that.coPays),
          mobileShowBenefitSummary: that.mobileShowBenefitSummary,
          mobileShowRemainingBalance: that.mobileShowRemainingBalance,
          mobileShowMcRemaining: that.mobileShowMcRemaining,
          mobileShowMcTaken: that.mobileShowMcTaken,
          hasMobileNpr: that.hasMobileNpr,
          coPay: that.hasCoPay ? that.coPay : null,
          requireReferral: that.requireReferral,
          dependentRequireReferral: that.dependentRequireReferral,
          excludeTypicallyUncoveredSpecialistTreatments:
            that.excludeTypicallyUncoveredSpecialistTreatments,
          principleInitialGuaranteeAmount: that.principleInitialGuaranteeAmount,
          dependentInitialGuaranteeAmount: that.dependentInitialGuaranteeAmount,
          principleAllowedTopUpAmount: that.principleAllowedTopUpAmount,
          dependentAllowedTopUpAmount: that.dependentAllowedTopUpAmount,
          principalReferralExceptions: that.principalReferralExceptions,
          dependentReferralExceptions: that.dependentReferralExceptions,
          principalGuaranteeAmountForHealthScreening:
            that.principalGuaranteeAmountForHealthScreening,
          dependentGuaranteeAmountForHealthScreening:
            that.dependentGuaranteeAmountForHealthScreening,
          customRemarksForHealthScreening: that.customRemarksForHealthScreening,
        };
      };

      that.refreshResult = function refreshResult() {
        const principalSummariesServiceType = [];
        const dependentSummariesServiceType = [];
        let showPrincipalFootnoteServiceType = false;
        let showDependentFootnoteServiceType = false;

        let coverDependentServiceType = !!that.hasDependentCovered;
        const principalBenefitsServiceType = _.map(
          _.filter(that.principalBenefitsServiceType, 'valid'),
          (x) => new BenefitResult(x, true, true)
        );

        const dependentBenefitsServiceType = coverDependentServiceType
          ? _.map(
              _.filter(that.dependentBenefitsServiceType, 'valid'),
              (x) => new BenefitResult(x, true, true)
            )
          : [];

        if (!dependentBenefitsServiceType.length) {
          coverDependentServiceType = false;
        }

        const coverPrincipalServiceType = principalBenefitsServiceType.length > 0;
        that.coverSpecialist =
          _.findIndex(principalBenefitsServiceType, ['treatment.id', 5]) > -1 ||
          _.findIndex(dependentBenefitsServiceType, ['treatment.id', 5]) > -1;
        that.coverPharmacy =
          _.findIndex(principalBenefitsServiceType, ['treatment.id', 6]) > -1 ||
          _.findIndex(dependentBenefitsServiceType, ['treatment.id', 6]) > -1;
        that.coverHealthScreening =
          _.findIndex(principalBenefitsServiceType, ['treatment.id', 9]) > -1 ||
          _.findIndex(dependentBenefitsServiceType, ['treatment.id', 9]) > -1;

        vm.hasDependentsOnlyModifier = false;

        // Group result into stacks

        _.each(that.modifiers, (mod) => {
          if (mod.cycle === 4 && mod.type !== 0) {
            mod.cycle = 3;
          }
        });

        // Modifiers

        _.each(
          _.groupBy(that.modifiers, (x) => {
            const interval = x.cycle === 0 ? x.interval : 1;
            return `${x.type}:${interval}:${x.cycle}`;
          }),
          (values) => {
            _.each(values, (modifier) => {
              const applicableTreatmentsServiceType = _.filter(
                modifier.treatmentsServiceType,
                (x) => x.applicable
              );

              const principalPortionServiceType = [];
              const dependentPortionServiceType = [];

              const beneficiary = coverDependentServiceType ? modifier.beneficiary : 1;
              let poolMode = coverDependentServiceType ? modifier.poolMode : 2;
              // Applicable treatment servicetype

              _.each(applicableTreatmentsServiceType, (t) => {
                let benefitServiceType;

                if (coverPrincipalServiceType && (beneficiary & 1) > 0) {
                  benefitServiceType = _.find(
                    principalBenefitsServiceType,
                    (x) => x.treatment.id === t.treatment.id
                  );

                  if (benefitServiceType) {
                    if (modifier.type === 0) {
                      benefitServiceType.unlimitedBudget = false;
                    } else if (modifier.type === 1) {
                      benefitServiceType.unlimitedVisit = false;
                    }
                    principalPortionServiceType.push(t.treatment);
                  }
                }

                // eslint-disable-next-line no-bitwise
                if (coverDependentServiceType && (beneficiary & 2) > 0) {
                  benefitServiceType = _.find(
                    dependentBenefitsServiceType,
                    (x) => x.treatment.id === t.treatment.id
                  );
                  if (benefitServiceType) {
                    if (modifier.type === 0) {
                      benefitServiceType.unlimitedBudget = false;
                    } else if (modifier.type === 1) {
                      benefitServiceType.unlimitedVisit = false;
                    }
                    dependentPortionServiceType.push(t.treatment);
                  }
                }
              });

              if (modifier.beneficiary === 2) vm.hasDependentsOnlyModifier = true;

              // Service Type.

              if (principalPortionServiceType.length || dependentPortionServiceType.length) {
                const uniquePortionServiceType = _.uniqBy(
                  _.concat(principalPortionServiceType, dependentPortionServiceType),
                  'id'
                );
                const modifierTreatmentNamesServiceType = _.join(
                  _.map(uniquePortionServiceType, 'name'),
                  '+'
                );
                const principalTreatmentNamesServiceType = _.join(
                  _.map(principalPortionServiceType, 'name'),
                  '+'
                );
                const dependentTreatmentNamesServiceType = _.join(
                  _.map(dependentPortionServiceType, 'name'),
                  '+'
                );

                if (
                  poolMode === 0 &&
                  (!principalPortionServiceType.length || !dependentPortionServiceType.length)
                ) {
                  poolMode = 2;
                }

                let principalSummaryRecordServiceType = null;
                if (principalTreatmentNamesServiceType) {
                  principalSummaryRecordServiceType = _.find(
                    principalSummariesServiceType,
                    (x) => x.key === principalTreatmentNamesServiceType
                  );

                  if (!principalSummaryRecordServiceType) {
                    principalSummaryRecordServiceType = new BenefitSummary(
                      principalTreatmentNamesServiceType
                    );
                    principalSummariesServiceType.push(principalSummaryRecordServiceType);
                  }
                }

                let dependentSummaryRecordServiceType = null;

                if (dependentTreatmentNamesServiceType) {
                  dependentSummaryRecordServiceType = _.find(
                    dependentSummariesServiceType,
                    (x) => x.key === dependentTreatmentNamesServiceType
                  );

                  if (!dependentSummaryRecordServiceType) {
                    dependentSummaryRecordServiceType = new BenefitSummary(
                      dependentTreatmentNamesServiceType
                    );
                    dependentSummariesServiceType.push(dependentSummaryRecordServiceType);
                  }
                }

                // Get modifier description

                let strServiceType = '';
                const strValueServiceType = _.isNil(modifier.value) ? '?' : modifier.value;
                switch (modifier.type) {
                  case 0:
                    strServiceType += ` ${ccyFilter(modifier.value, vm.currencyCode)}`;
                    break;
                  case 1:
                    strServiceType += ` ${strValueServiceType} ${App.localize(
                      'VisitLimit'
                    ).toLowerCase()}`;
                    break;
                  case 2:
                    strServiceType += ` ${ccyFilter(
                      modifier.value,
                      vm.currencyCode
                    )} (${App.localize('BudgetOverdraft').toLowerCase()})`;
                    break;
                  case 3:
                    strServiceType += ` ${strValueServiceType}${App.localize(
                      'VisitOverdraft'
                    ).toLowerCase()}`;
                    break;
                  default:
                    break;
                }

                let cycleServiceType = '';
                switch (modifier.cycle) {
                  case 0:
                    cycleServiceType = App.localize('EveryXYears', modifier.interval).toLowerCase();
                    break;
                  case 1:
                    cycleServiceType = App.localize('Annually').toLowerCase();
                    break;
                  case 2:
                    cycleServiceType = App.localize('Monthly').toLowerCase();
                    break;
                  case 3:
                    cycleServiceType = App.localize('Daily').toLowerCase();
                    break;
                  case 4:
                    cycleServiceType = App.localize('PerVisit').toLowerCase();
                    break;
                  case 5:
                    cycleServiceType = App.localize('Every6Months').toLowerCase();
                    break;
                  case 6:
                    cycleServiceType = App.localize('Every4Months').toLowerCase();
                    break;
                  case 7:
                    cycleServiceType = App.localize('Every3Months').toLowerCase();
                    break;
                  case 8:
                    cycleServiceType = App.localize('Every2Months').toLowerCase();
                    break;
                  default:
                    break;
                }
                strServiceType += ` ${cycleServiceType}`;

                let allStrServiceType;
                let principalStrServiceType;
                let dependentStrServiceType;

                if (poolMode === 0) {
                  allStrServiceType = strServiceType;
                  if (principalTreatmentNamesServiceType !== dependentTreatmentNamesServiceType) {
                    if (principalPortionServiceType.length) {
                      allStrServiceType += ` (${App.localize(
                        'Principal'
                      )}: ${principalTreatmentNamesServiceType})`;
                    }
                    if (dependentPortionServiceType.length) {
                      allStrServiceType += ` (${App.localize(
                        'Dependent'
                      )}: ${dependentTreatmentNamesServiceType})`;
                    }
                  }

                  if (principalSummaryRecordServiceType) {
                    principalSummaryRecordServiceType.addItem(
                      modifier.type,
                      cycleServiceType,
                      '[1]',
                      modifier.value || 0
                    );
                    showPrincipalFootnoteServiceType = true;
                  }
                  if (dependentSummaryRecordServiceType) {
                    dependentSummaryRecordServiceType.addItem(
                      modifier.type,
                      cycleServiceType,
                      '[1]',
                      modifier.value || 0
                    );
                    showDependentFootnoteServiceType = true;
                  }
                } else if (poolMode === 1) {
                  principalStrServiceType = '';
                  if (principalPortionServiceType.length) {
                    principalStrServiceType = strServiceType;
                    if (principalTreatmentNamesServiceType !== modifierTreatmentNamesServiceType) {
                      principalStrServiceType += ` (${principalTreatmentNamesServiceType})`;
                    }
                  }

                  dependentStrServiceType = '';
                  if (dependentPortionServiceType.length) {
                    dependentStrServiceType = strServiceType;
                    if (dependentTreatmentNamesServiceType !== modifierTreatmentNamesServiceType) {
                      dependentStrServiceType += ` (${dependentTreatmentNamesServiceType})`;
                    }
                  }

                  if (principalSummaryRecordServiceType) {
                    principalSummaryRecordServiceType.addItem(
                      modifier.type,
                      cycleServiceType,
                      '',
                      modifier.value || 0
                    );
                  }
                  if (dependentSummaryRecordServiceType) {
                    dependentSummaryRecordServiceType.addItem(
                      modifier.type,
                      cycleServiceType,
                      '',
                      modifier.value || 0
                    );
                  }
                } else if (poolMode === 2) {
                  principalStrServiceType = '';
                  if (principalPortionServiceType.length) {
                    principalStrServiceType = strServiceType;
                    if (principalTreatmentNamesServiceType !== modifierTreatmentNamesServiceType) {
                      principalStrServiceType += ` (${principalTreatmentNamesServiceType})`;
                    }
                  }

                  dependentStrServiceType = '';
                  if (dependentPortionServiceType.length) {
                    dependentStrServiceType = strServiceType;
                    if (dependentTreatmentNamesServiceType !== modifierTreatmentNamesServiceType) {
                      dependentStrServiceType += ` (${dependentTreatmentNamesServiceType})`;
                    }
                  }

                  if (principalSummaryRecordServiceType) {
                    principalSummaryRecordServiceType.addItem(
                      modifier.type,
                      cycleServiceType,
                      '',
                      modifier.value || 0
                    );
                  }
                  if (dependentSummaryRecordServiceType) {
                    dependentSummaryRecordServiceType.addItem(
                      modifier.type,
                      cycleServiceType,
                      '[2]',
                      modifier.value || 0
                    );
                    showDependentFootnoteServiceType = true;
                  }
                }
              }
            });
          }
        );

        // Print summary

        principalBenefitsServiceType.forEach((x) => {
          if (x.unlimitedVisit || x.unlimitedBudget) {
            let summary = _.find(principalSummariesServiceType, (y) => y.key === x.treatment.name);
            if (!summary) {
              summary = new BenefitSummary(x.treatment.name);
              principalSummariesServiceType.push(summary);
            }

            if (x.unlimitedBudget && x.unlimitedVisit) {
              summary.addText(App.localize('Unlimited'));
            } else {
              if (x.unlimitedBudget) {
                summary.addText(App.localize('UnlimitedBudget'));
              }
              if (x.unlimitedVisit) {
                summary.addText(App.localize('UnlimitedVisit'));
              }
            }
          }
        });

        that.principalSummariesServiceType = principalSummariesServiceType;
        that.showPrincipalFootnoteServiceType = showPrincipalFootnoteServiceType;

        dependentBenefitsServiceType.forEach((x) => {
          if (x.unlimitedVisit || x.unlimitedBudget) {
            let summary = _.find(dependentSummariesServiceType, (y) => y.key === x.treatment.name);
            if (!summary) {
              summary = new BenefitSummary(x.treatment.name);
              dependentSummariesServiceType.push(summary);
            }

            if (x.unlimitedBudget && x.unlimitedVisit) {
              summary.addText(App.localize('Unlimited'));
            } else {
              if (x.unlimitedBudget) {
                summary.addText(App.localize('UnlimitedBudget'));
              }
              if (x.unlimitedVisit) {
                summary.addText(App.localize('UnlimitedVisit'));
              }
            }
          }
        });

        that.dependentSummariesServiceType = dependentSummariesServiceType;
        that.showDependentFootnoteServiceType = showDependentFootnoteServiceType;

        const dependentSpecialistBenefitServiceType = _.find(
          that.dependentBenefitsServiceType,
          (b) => b.name === 'Specialist'
        );
        if (dependentSpecialistBenefitServiceType) {
          vm.dependentHasSpecialist = dependentSpecialistBenefitServiceType.valid;
        }

        const dependentHealthScreeningBenefitServiceType = _.find(
          that.dependentBenefitsServiceType,
          (b) => b.name === 'Health screening'
        );
        if (dependentHealthScreeningBenefitServiceType) {
          vm.dependentHasHealthScreening = dependentHealthScreeningBenefitServiceType.valid;
        }
      };

      // Initialization

      if (dto) {
        automap(dto, that);

        if (!(that.hasCoPay = !!that.coPay)) {
          that.coPay = {
            isPercentage: true,
            value: null,
          };
        }

        that.principalBenefitsServiceType = buildTreatmentTypeList(
          dto.customTreatmentTypes,
          dto.principalBenefitsServiceType
        );
        that.principalGuaranteeLetterSources = new GuaranteeLetterSources(
          dto.principalGuaranteeLetterSources
        );
        that.dependentBenefitsServiceType = buildTreatmentTypeList(
          dto.customTreatmentTypes,
          dto.dependentBenefitsServiceType
        );
        that.dependentGuaranteeLetterSources = new GuaranteeLetterSources(
          dto.dependentGuaranteeLetterSources
        );
        that.hasDependentCovered =
          dto.dependentBenefitsServiceType && (dto.dependentBenefitsServiceType.length > 0 ? 1 : 0);
        that.requireReferral = dto.requireReferral;
        that.excludeTypicallyUncoveredSpecialistTreatments =
          dto.excludeTypicallyUncoveredSpecialistTreatments;

        that.modifiers = [];
        _.each(dto.modifiers, (x) => {
          that.modifiers.push(new Modifier(that, x));
        });

        that.coPays = [];
        _.each(dto.coPays, (x) => {
          that.coPays.push(new CoPay(that, x));
        });
      }

      that.refreshResult();
    }

    function GuaranteeLetterSource(id, displayName, isDisabled, valid) {
      this.id = id;
      this.displayName = displayName;
      this.isDisabled = !!isDisabled;
      this.valid = !!valid;
    }

    function GuaranteeLetterSources(value) {
      this.sources = [];

      this.toDto = () => _(this.sources).filter('valid').sumBy('id');

      this.sources.push(new GuaranteeLetterSource(0, App.localize('Corporate'), true, true));
      this.sources.push(new GuaranteeLetterSource(1, App.localize('MobileApp'), false, value & 1));
      this.sources.push(new GuaranteeLetterSource(2, App.localize('Hospital'), false, value & 2));
    }

    function CoPay(bgModel, dto) {
      const that = this;

      that.parent = bgModel;

      // Basic properties

      that.beneficiary = 3;
      that.isPercentage = true;
      that.value = null;

      // View properties

      that.show = true;

      // Treatment type mapping

      that.principalMapServiceType = _.keyBy(bgModel.principalBenefitsServiceType, 'id');
      that.dependentMapServiceType = _.keyBy(bgModel.dependentBenefitsServiceType, 'id');

      // Applicable treatment types

      const treatmentsServiceType = [];
      _.each(that.parent.principalBenefitsServiceType, (e) => {
        const isValid = checkValidForServiceType(that, e.id);

        // HM-5939 - Copayment not applicable for Health Screening.

        if (e.id !== 9)
          treatmentsServiceType.push(
            new TreatmentApplyModel(new TreatmentModel(e.id, e.name, e.icon, isValid), isValid)
          );
      });
      that.treatmentsServiceType = treatmentsServiceType;

      // Public functions

      that.changed = () => {
        that.refreshTreatmentValidityServiceType();
      };

      that.refreshTreatmentValidityServiceType = () => {
        _.each(that.treatmentsServiceType, (e) => {
          e.treatment.valid = checkValidForServiceType(that, e.treatment.id);
        });
      };

      that.toDto = function toDto() {
        return {
          beneficiary: that.beneficiary,
          isPercentage: that.isPercentage,
          value: that.value,
          affectedBenefitsServiceType: _(treatmentsServiceType)
            .filter('applicable')
            .map('treatment.id')
            .value(),
        };
      };

      // Initialization

      if (dto) {
        automap(dto, that);
        _.each(that.treatmentsServiceType, (x) => {
          x.applicable = _.indexOf(dto.affectedBenefitsServiceType, x.treatment.id) >= 0;
        });
        that.refreshTreatmentValidityServiceType();
      }
    }

    function Modifier(bgModel, dto) {
      const that = this;

      that.parent = bgModel;

      // Basic properties

      that.type = 0;
      that.cycle = 1;
      that.baseYearRenewal = null;
      that.beneficiary = 3;
      that.poolMode = 0;
      that.value = null;
      that.interval = 2;
      that.renewalMode = 0;

      // View properties

      that.show = true;
      that.description = '';

      // Treatment type mapping

      that.principalMapServiceType = _.keyBy(bgModel.principalBenefitsServiceType, 'id');
      that.dependentMapServiceType = _.keyBy(bgModel.dependentBenefitsServiceType, 'id');

      // Applicable treatment types

      const treatmentsServiceType = [];
      _.each(that.parent.principalBenefitsServiceType, (e) => {
        const isValid = checkValidForServiceType(that, e.id);
        treatmentsServiceType.push(
          new TreatmentApplyModel(new TreatmentModel(e.id, e.name, e.icon, isValid), isValid)
        );
      });
      that.treatmentsServiceType = treatmentsServiceType;

      function validateModifierRules() {
        // If modifier is not budget limit, cycle cannot be Per Visit.
        // Default to annually.

        if (that.type !== 0 && that.cycle === 4) {
          that.cycle = 1;
        }

        // If beneficiary is principal only, there is no pool to share.
        // If beneficiary is dependant only, split pool cannot be selected.
        // Default to individual pool.

        if (that.beneficiary === 1 || (that.beneficiary === 2 && that.poolMode === 2)) {
          that.poolMode = 1;
        }
      }

      // Public functions

      that.changed = function changed() {
        validateModifierRules();
        that.refreshTreatmentValidityServiceType();
        that.refreshDescription();
      };

      that.refreshCycle = () => {
        if(that.renewalMode === 1 && that.cycle !== 0 && that.cycle !== 1)
        {
          that.cycle = null;
        }

        const form = $scope.benefitGroupCreateOrEditForm;
        App.touchFormErrors(form);
      };

      that.refreshTreatmentValidityServiceType = () => {
        _.each(that.treatmentsServiceType, (e) => {
          e.treatment.valid = checkValidForServiceType(that, e.treatment.id);
        });
      };

      that.toDto = function toDto() {
        return {
          type: that.type,
          cycle: that.cycle,
          beneficiary: that.beneficiary,
          poolMode: that.poolMode,
          value: that.value,
          interval: that.interval,
          baseYearRenewal: that.baseYearRenewal,
          affectedBenefitsServiceType: _(treatmentsServiceType)
            .filter('applicable')
            .map('treatment.id')
            .value(),
          renewalMode: that.renewalMode,
        };
      };

      that.refreshDescription = function refreshDescription() {
        const thisType = that.type;
        const thisCycle = that.cycle;
        const thisInterval = that.interval;
        const thisBeneficiary = that.beneficiary;
        const thisPoolMode = that.poolMode;
        const thisValue = that.value;
        const thisTreatmentTypes = that.treatmentTypes;
        let desc = '';
        const ttNames = [];

        switch (thisCycle) {
          case 0:
            desc += `Every ${thisInterval} year(s)`;
            break;
          case 1:
            desc += 'Every year';
            break;
          case 2:
            desc += 'Every month';
            break;
          case 3:
            desc += 'Everyday';
            break;
          case 4:
            desc += 'Every visit';
            break;
          default:
            break;
        }

        switch (thisType) {
          case 0:
          case 1:
            desc += ' limits ';
            break;
          case 2:
          case 3:
            desc += ' allows overdraft of ';
            break;
          default:
            break;
        }

        switch (thisType) {
          case 0:
          case 2:
            desc += `${vm.currencyCode} ${thisValue}`;
            break;
          case 1:
          case 3:
            desc += `${thisValue} visits`;
            break;
          default:
            break;
        }

        switch (thisBeneficiary) {
          case 1:
            desc += ' for principal';
            break;
          case 2:
            desc += ' for dependants';
            break;
          case 3:
            desc += ' for principal & dependants';
            break;
          default:
            break;
        }

        switch (thisPoolMode) {
          case 0:
            desc += ' where everyone share';
            break;
          case 1:
            desc += ' individually';
            break;
          case 2:
            desc += ' where dependents share';
            break;
          default:
            break;
        }

        if (thisTreatmentTypes) {
          desc += ' on ';

          _.each(thisTreatmentTypes, (isApplied, idStr) => {
            const id = parseInt(idStr, 10);
            if (isApplied) {
              ttNames.push(_.find(vm.treatmentTypes, (a) => a.id === id).name);
            }
          });

          desc += ttNames.join(', ');
        }

        that.description = ttNames.length > 0 ? desc : 'Not applicable';
      };

      // Initialization

      if (dto) {
        automap(dto, that);
        _.each(that.treatmentsServiceType, (x) => {
          x.applicable = _.indexOf(dto.affectedBenefitsServiceType, x.treatment.id) >= 0;
          that.baseYearRenewal = !_.isNull(that.baseYearRenewal)
            ? moment(that.baseYearRenewal)
            : null;
        });
        that.refreshTreatmentValidityServiceType();
      }

      that.refreshDescription();
    }

    function TreatmentModel(id, name, icon, valid) {
      this.id = id;
      this.name = name;
      this.icon = icon;
      this.valid = valid;
    }

    function TreatmentApplyModel(treatment, applicable) {
      this.treatment = treatment;
      this.applicable = applicable;
    }

    function BenefitResult(treatment, unlimitedBudget, unlimitedVisit) {
      this.treatment = treatment;
      this.unlimitedBudget = unlimitedBudget;
      this.unlimitedVisit = unlimitedVisit;
    }

    function BenefitSummaryItem(type, cycle, poolMode, value) {
      this.type = type;
      this.cycle = cycle;
      this.poolMode = poolMode;
      this.value = value;

      this.toString = function () {
        let str = '';
        const strValue = _.isNil(this.value) ? '?' : this.value;
        switch (this.type) {
          case 0:
            str += ` ${ccyFilter(strValue, vm.currencyCode)}`;
            break;
          case 1:
            str += `${strValue} ${this.value > 1 ? 'visits' : 'visit'}`;
            break;
          case 2:
            str += ` ${ccyFilter(strValue, vm.currencyCode)} overdraft`;
            break;
          case 3:
            str += `${strValue} overdraft ${this.value > 1 ? 'visits' : 'visit'}`;
            break;
          default:
            break;
        }

        str += ` ${this.cycle} ${this.poolMode}`;
        return str;
      };
    }

    function BenefitSummary(key, value) {
      this.items = [];
      this.texts = [];

      this.key = key;
      this.value = value;

      this.addItem = function addItem(type, cycle, poolMode, childValue) {
        const item = _.find(
          this.items,
          (x) => x.type === type && x.cycle === cycle && x.poolMode === poolMode
        );

        if (!item) {
          this.items.push(new BenefitSummaryItem(type, cycle, poolMode, childValue));
        } else {
          item.value += childValue;
        }
      };

      this.addText = function addText(text) {
        this.texts.push(text);
      };

      this.toString = function toString() {
        return _.concat(
          _.map(this.items, (x) => x.toString()),
          this.texts
        ).join(', ');
      };
    }

    /* End of Types */

    function save() {
      if (vm.benefitGroup.id) {
        showConfirmationModal();
      } else {
        createOrUpdateBenefitGroup();
      }
    }

    function createOrUpdateBenefitGroup() {
      if (vm.saving) return;

      // Gets all accessible treatment IDs.

      let benefitGrantedTreatmentIds = null;
      let benefitGrantedPharmacyTreatmentIds = null;
      if (!vm.isPreEmployment) {
        benefitGrantedTreatmentIds = vm.treatmentEditData.grantedTreatmentIds;
        if (vm.hasSpecialist) {
          benefitGrantedTreatmentIds = benefitGrantedTreatmentIds.concat(
            vm.specialistTreatmentEditData.grantedTreatmentIds
          );
        }
        if (vm.hasHealthScreening) {
          benefitGrantedTreatmentIds = benefitGrantedTreatmentIds.concat(
            vm.healthScreeningTreatmentEditData.grantedTreatmentIds
          );
        }
        if (vm.hasPharmacy) {
          benefitGrantedPharmacyTreatmentIds = vm.pharmacyTreatmentEditData.grantedTreatmentIds;
        }
      }

      vm.saving += 1;
      benefitGroupSvc
        .createOrUpdateBenefitGroup({
          benefitGroup: vm.benefitGroup.toDto(),
          grantedTreatmentIds: benefitGrantedTreatmentIds,
          grantedPharmacyTreatmentIds: benefitGrantedPharmacyTreatmentIds,
        })
        .success(() => {
          abp.notify.info(App.localize('SuccessfullySaved'));
          $state.go('corporate.benefitGroups');
        })
        .finally(() => {
          vm.saving -= 1;
        });
    }

    function showConfirmationModal() {
      const modal = $modal.open({
        templateUrl: require('./confirmation.modal.html'),
        controller: 'corporate.views.benefitGroups.createEdit.confirmationModal as vm',
        size: 'lg',
        backdrop: 'static',
        resolve: {
          originalBenefitGroup: () => vm.preChangeBenefitGroup,
          changedBenefitGroup: () => vm.benefitGroup,
          currencyCode: () => vm.currencyCode,
          hasSupportPlans: () => vm.hasSupportPlans,
          supportPlans: () => vm.supportPlans,
          isPreEmployment: () => vm.isPreEmployment,
          dependentHasSpecialist: () => vm.dependentHasSpecialist,
          originalSpecialistTreatmentEditData: () => vm.preChangeSpecialistTreatmentEditData,
          changedSpecialistTreatmentEditData: () => vm.specialistTreatmentEditData,
          hasDependentsOnlyModifier: () => vm.hasDependentsOnlyModifier,
          coPayExample: () => vm.coPayExample,
          showCoPayExample: () => vm.showCoPayExample,
          originalTreatmentEditData: () => vm.preChangeTreatmentEditData,
          changedTreatmentEditData: () => vm.treatmentEditData,
          originalPharmacyTreatmentEditData: () => vm.preChangePharmacyTreatmentEditData,
          changedPharmacyTreatmentEditData: () => vm.pharmacyTreatmentEditData,
          corporateHasMobileNpr: () => vm.corporateHasMobileNpr,
          hasBasicsChanged: () => vm.basicsForm && vm.basicsForm.$dirty,
          hasBenefitsChanged: () => vm.benefitsForm && vm.benefitsForm.$dirty,
          hasClaimPolicyChanged: () => vm.claimPolicyForm && vm.claimPolicyForm.$dirty,
          hasCopayChanged: () => vm.copayForm && vm.copayForm.$dirty,
          hasMobileAppChanged: () => vm.mobileAppForm && vm.mobileAppForm.$dirty,
          hasPharmacyChanged: () => vm.isPharmacyDirty,
          hasSpecialistChanged: () =>
            (vm.specialistForm && vm.specialistForm.$dirty) || vm.isSpecialistTreatmentsDirty,
          hasAccessibleTreatmentsChanged: () => vm.isAccessibleTreatmentsDirty,
          originalHealthScreeningTreatmentEditData: () =>
            vm.preChangeHealthScreeningTreatmentEditData,
          hasHealthScreeningChanged: () =>
            (vm.healthScreeningForm && vm.healthScreeningForm.$dirty) ||
            vm.isHealthScreeningTreatmentsDirty,
          changedHealthScreeningTreatmentEditData: () => vm.healthScreeningTreatmentEditData,
          dependentHasHealthScreening: () => vm.dependentHasHealthScreening,
        },
      });
      modal.result.then(() => {
        createOrUpdateBenefitGroup();
      });
    }

    function checkValidForServiceType(_this, id) {
      switch (_this.beneficiary) {
        case 3:
          return (
            _this.principalMapServiceType[id].valid ||
            (_this.parent.hasDependentCovered === 1 && _this.dependentMapServiceType[id].valid)
          );
        case 1:
          return _this.principalMapServiceType[id].valid;
        case 2:
          return _this.dependentMapServiceType[id].valid;
        default:
          return false;
      }
    }

    init();
  }
})();
