(() => {
  angular
    .module('app')
    .controller('common.views.profile.manageMyAccount', ManageMyAccountController);

  ManageMyAccountController.$inject = ['$state', '$uibModalInstance', 'abp.services.app.profile'];

  function ManageMyAccountController($state, $uibModalInstance, profileSvc) {
    const vm = this;
    vm.loading = 0;
    vm.emailAddress = '';
    vm.validEmailAddress = false;
    vm.userAccountSettings = null;

    vm.hasSecurityEnhancement2 = abp.setting.getBoolean('Hms.Feature.SecurityEnhancement2');

    vm.cancel = cancel;
    vm.save = save;
    vm.navigateToManageTwoFactorAuth = navigateToManageTwoFactorAuth;

    init();

    function init() {
      getUserAccountSettings();
    }

    function getUserAccountSettings() {
      vm.loading += 1;
      profileSvc
        .getUserAccountSettings()
        .success((data) => {
          vm.userAccountSettings = data;
          vm.emailAddress = data.emailAddress;
          vm.validEmailAddress = vm.emailAddress != null;
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function navigateToManageTwoFactorAuth() {
      $uibModalInstance.dismiss();
      $state.go('manageTwoFactorAuth');
    }

    function save() {
      const { emailAddress, twoFactorAuthentication, ...payload } = vm.userAccountSettings;
      vm.loading += 1;
      profileSvc
        .saveUserAccountSettings(payload)
        .success(() => {
          abp.notify.success(App.localize('SuccessfullySaved'));
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function cancel() {
      $uibModalInstance.dismiss();
    }
  }
})();
