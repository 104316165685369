(() => {
  angular.module('finance').component('makePaymentListingComponent', {
    templateUrl: require('./makePaymentListing.component.html'),
    controller: MakePaymentListingComponent,
    controllerAs: 'vm',
    bindings: {
      selectedInvoices: '=',
      pageState: '=',
      calculateTotalAmount: '<',
    },
  });

  MakePaymentListingComponent.$inject = [
    '$scope',
    '$timeout',
    'financeConstants',
    'abp.services.finance.saleTransactions',
    'Hms.Finance.TransactionType',
    'Hms.SaleTransactions.Status',
  ];

  function MakePaymentListingComponent(
    $scope,
    $timeout,
    financeConsts,
    saleTransactionSvc,
    enumTransactionType,
    enumSaleTransactionStatus
  ) {
    const vm = this;

    vm.loading = 0;
    vm.totalAmount = 0;
    vm.currencyCode = 'MYR';
    vm.invoicesInstructions = App.localize('MakePaymentInvoicesInstructions');
    vm.transactionCategories = [];

    vm.enums = {
      transactionType: enumTransactionType,
      transactionStatus: enumSaleTransactionStatus,
    };

    vm.requestParams = {
      skipCount: 0,
      maxResultCount: financeConsts.grid.defaultPageSize,
      sorting: '',
    };

    vm.gridOptions = {
      appScopeProvider: vm,
      paginationPageSizes: financeConsts.grid.defaultPageSizes,
      paginationPageSize: financeConsts.grid.defaultPageSize,
      paginationCurrentPage: 1,
      useExternalPagination: true,
      useExternalSorting: true,
      enableSelectAll: false,
      multiSelect: true,
      columnDefs: [
        {
          displayName: App.localize('Invoice'),
          enableSorting: false,
          field: 'transactionNumber',
          minWidth: 160,
        },
        {
          displayName: App.localize('TransactionDate'),
          enableSorting: false,
          field: 'date',
          cellFilter: "momentFormat: 'L'",
          minWidth: 160,
        },
        {
          displayName: App.localize('DueDate'),
          enableSorting: false,
          field: 'dueDate',
          cellFilter: "momentFormat: 'L'",
          minWidth: 160,
        },
        {
          displayName: App.localize('InvoiceCategory'),
          enableSorting: false,
          field: 'category',
          minWidth: 200,
        },
        {
          displayName: App.localize('OutstandingBalance'),
          enableSorting: false,
          cellFilter: 'currencyFormat: row.entity.currencyCode',
          field: 'outstandingAmount',
          minWidth: 160,
        },
      ],
      onRegisterApi(gridApi) {
        $scope.gridApi = gridApi;
        $scope.gridApi.core.on.sortChanged($scope, (scope, callback) => {
          vm.requestParams.sorting =
            callback.length && callback[0].field
              ? `${callback[0].field} ${callback[0].sort.direction}`
              : null;
          getInvoices();
        });
        gridApi.pagination.on.paginationChanged(
          $scope,
          App.setPagination(vm.requestParams, getInvoices)
        );
        gridApi.selection.on.rowSelectionChanged($scope, updateSelectedInvoices);
      },
      data: [],
    };

    vm.addToCart = addToCart;
    vm.getInvoices = getInvoices;

    init();

    function init() {
      getInvoices();
    }

    function addToCart() {
      vm.pageState = 1;
    }

    function getInvoices() {
      let input = $.extend(
        {
          transactionType: vm.enums.transactionType.Invoice.id,
          transactionStatusList: [
            vm.enums.transactionStatus.Open.id,
            vm.enums.transactionStatus.PartiallyPaid.id,
          ],
        },
        vm.requestParams
      );

      vm.loading += 1;
      saleTransactionSvc
        .getSaleTransactions(input)
        .success((data) => {
          vm.gridOptions.totalItems = data.totalCount;
          vm.gridOptions.data = data.items;
          vm.transactionCategories = data.transactionCategories;
          localizeTransactionCategories(vm.gridOptions.data);
        })
        .finally(() => {
          vm.loading -= 1;
          $timeout(() => refreshSelection());
        });
    }

    function updateSelectedInvoices(invoice) {
      let index = vm.selectedInvoices
        .map((selectedInvoice) => selectedInvoice.id)
        .indexOf(invoice.entity.id);

      if (invoice.isSelected && index === -1) vm.selectedInvoices.push(invoice.entity);
      if (!invoice.isSelected) vm.selectedInvoices.splice(index, 1);

      vm.totalAmount = vm.calculateTotalAmount(vm.selectedInvoices);
    }

    function refreshSelection() {
      _.forEach(vm.selectedInvoices, (selectedInvoice) => {
        let index = vm.gridOptions.data.map((invoice) => invoice.id).indexOf(selectedInvoice.id);
        $scope.gridApi.selection.toggleRowSelection(vm.gridOptions.data[index]);
      });
    }

    function localizeTransactionCategories(data) {
      _.forEach(vm.transactionCategories, (transactionCategory) => {
        if (transactionCategory.isSystem) {
          transactionCategory.displayName = App.localize(transactionCategory.displayName);
        }
      });

      _.forEach(data, (transaction) => {
        transaction.category = vm.transactionCategories.find(
          (category) => category.id === transaction.transactionCategoryId
        ).displayName;
      });
    }
  }
})();
