import swal from 'sweetalert';

(() => {
  const componentId = 'hostCorporatesMasterPoliciesMasterPolicyContainerComponent';

  angular.module('app').component(componentId, {
    templateUrl: require('./container.component.html'),
    controller: MasterPolicyContainerController,
    controllerAs: 'vm',
  });

  MasterPolicyContainerController.$inject = [
    '$stateParams',
    '$state',
    'abp.services.app.corporate',
    'abp.services.app.masterPolicy',
    'moment',
  ];

  function MasterPolicyContainerController(
    $stateParams,
    $state,
    corporateSvc,
    masterPolicySvc,
    moment
  ) {
    const vm = this;

    vm.loading = 0;
    vm.organizationId = $stateParams.organizationId;
    vm.masterPolicyId = $stateParams.masterPolicyId;
    vm.isRenewal = $stateParams.isRenewal;
    vm.newAttachments = [];
    vm.isCreate = vm.masterPolicyId === null;
    vm.isEdit = false;
    const widgets = [];

    // Date range model for effective master policy date.
    vm.effectiveDateRangeModel = {
      startDate: moment().startOf('day').utc(),
      endDate: moment().endOf('day').utc(),
    };

    vm.toggleEdit = toggleEdit;
    vm.cancel = cancel;
    vm.saveAll = saveAll;
    vm.addWidget = addWidget;

    init();

    function init() {
      // Either get name through corporate app service or pass in name
      // through masterPolicies.page.cshtml as stateParams.

      vm.loading += 1;
      corporateSvc
        .getOrganizationName({
          id: vm.organizationId,
        })
        .success((data) => {
          vm.organizationName = data.name;
        })
        .finally(() => {
          vm.loading -= 1;
        });

      // Get master policy for creation/view.

      getMasterPolicy();
    }

    // Register child widget to this container.
    // Expose to child widget to register themselves.

    function addWidget(widget) {
      if (widget) {
        widgets.push(widget);
      }
    }

    // To validate all widgets before saving the changes.
    function saveAll() {
      let error = false;

      if (vm.saving || vm.loading) return;

      _.each(widgets, (w) => {
        if (!w.validateForm()) {
          error = true;
        }
      });

      // Stop saving if any error occurs.

      if (error) {
        swal({
          title: App.localize('IncompleteSubmission'),
          text: App.localize('PleaseFillInRequiredInformationWhenCreatingSubscriptionNarrative'),
          type: 'warning',
        });
      } else {
        save();
      }
    }

    // Save function.
    function save() {
      const saveTitle =
        vm.isRenewal || vm.isEdit
          ? App.localize('AreYouSure')
          : App.localize('ConfirmCreateMasterPolicyQuestion');

      const saveMessage =
        vm.isRenewal && !vm.isEdit
          ? App.localize('ConfirmRenewMasterPolicyNarrative')
          : App.localize('ThisWillBeSendToSystemForProcessingNarrative');

      swal(
        {
          title: saveTitle,
          text: saveMessage,
          type: 'warning',
          confirmButtonText: App.localize('Yes'),
          cancelButtonText: App.localize('No'),
          showCancelButton: true,
          closeOnConfirm: true,
          closeOnCancel: true,
        },
        (isConfirm) => {
          if (isConfirm) {
            if (vm.isEdit) {
              edit();
            } else {
              createMasterPolicy();
            }
          } else {
            swal.close();
          }
        }
      );
    }

    // Save successful modal when the creation/edition of subscription
    // edit JSON object is successful.
    function saveSuccessfullyModal() {
      swal(
        {
          title: App.localize('SuccessfullyCreated'),
          text: App.localize('MasterPolicySuccessfullyCreated'),
          type: 'success',
          confirmButtonText: App.localize('ViewListing'),
          showCancelButton: false,
        },
        () => {
          $state.go(
            'host.corporateMasterPolicies',
            {
              organizationId: vm.organizationId,
            },
            {
              reload: true,
            }
          );
          swal.close();
        }
      );
    }

    function edit() {
      vm.loading += 1;

      validateEnrolmentRulesCoverage();

      masterPolicySvc
        .editMasterPolicy({
          masterPolicyId: vm.masterPolicyId,
          insurerTeamId: vm.data.masterPolicy.insurerTeamId,
          masterPolicyNumber: vm.data.masterPolicy.masterPolicyNumber,
          startDate: vm.effectiveDateRangeModel.startDate,
          endDate: moment(vm.effectiveDateRangeModel.endDate).format('l'),
          settings: vm.data.masterPolicy.settings,
          policyJacketAttachments: vm.newAttachments,
        })
        .then(() => {
          abp.notify.info(App.localize('SuccessfullySaved'));
        })
        .finally(() => {
          vm.loading -= 1;
          $state.reload();
        });
    }

    // Create a master policy.
    function createMasterPolicy() {
      vm.data.masterPolicy.startDate = vm.effectiveDateRangeModel.startDate.utc();
      vm.data.masterPolicy.endDate = vm.effectiveDateRangeModel.endDate.utc();

      if (!vm.isRenewal) {
        // Join all enrolment rule objects into one list.

        vm.data.masterPolicy.settings.enrolmentRules.push(
          vm.data.masterPolicy.settings.enrolmentRules.principal
        );
        vm.data.masterPolicy.settings.enrolmentRules.push(
          vm.data.masterPolicy.settings.enrolmentRules.spouse
        );
        vm.data.masterPolicy.settings.enrolmentRules.push(
          vm.data.masterPolicy.settings.enrolmentRules.child
        );
        vm.data.masterPolicy.settings.enrolmentRules.push(
          vm.data.masterPolicy.settings.enrolmentRules.parents
        );
      }

      validateEnrolmentRulesCoverage();

      // Check to see if copied over attachment from related master policy is deleted
      // during renewal process.

      const attachmentsMarkedForRemoval = _.map(
        _.filter(vm.data.masterPolicy.jacketPolicyAttachments, (d) => d.isDelete),
        'id'
      );

      vm.saving += 1;
      masterPolicySvc
        .createMasterPolicy({
          organizationId: vm.organizationId,
          masterPolicy: vm.data.masterPolicy,
          policyJacketAttachments: vm.newAttachments,
          policyJacketsMarkedForRemoval: attachmentsMarkedForRemoval,
          copyOverAttachments: vm.isRenewal,
        })
        .success(() => {
          saveSuccessfullyModal();
        })
        .finally(() => {
          vm.saving -= 1;
        });
    }

    function validateEnrolmentRulesCoverage() {
      _.forEach(vm.data.masterPolicy.settings.enrolmentRules, (x) => {
        if (!x.isCovered) {
          x.minEntryAge = 0;
          x.maxEntryAge = 0;
          x.maxRenewalAge = 0;
        }
      })
    }

    function getMasterPolicy() {
      vm.loading += 1;
      masterPolicySvc
        .getMasterPolicy({
          organizationId: vm.organizationId,
          masterPolicyId: vm.masterPolicyId,
          isRenewal: vm.isRenewal,
        })
        .success((data) => {
          vm.data = data;

          if (vm.isCreate) {
            const enrolmentRule = {
              isPrincipal: null,
              relationship: null,
              isCovered: null,
              minEntryAge: null,
              maxEntryAge: null,
              maxRenewalAge: null,
              nationalityCodes: [],
              specialCondition: null,
            };

            vm.data.masterPolicy.settings.enrolmentRules.principal = angular.copy(enrolmentRule);
            vm.data.masterPolicy.settings.enrolmentRules.spouse = angular.copy(enrolmentRule);
            vm.data.masterPolicy.settings.enrolmentRules.child = angular.copy(enrolmentRule);
            vm.data.masterPolicy.settings.enrolmentRules.parents = angular.copy(enrolmentRule);
          } else {
            vm.effectiveDateRangeModel = {
              startDate: vm.data.masterPolicy.startDate
                ? moment(vm.data.masterPolicy.startDate)
                : null,
              endDate: vm.data.masterPolicy.endDate ? moment(vm.data.masterPolicy.endDate) : null,
            };

            const { enrolmentRules } = data.masterPolicy.settings;

            _.each(enrolmentRules, (rule) => {
              if (rule.isPrincipal) vm.data.masterPolicy.settings.enrolmentRules.principal = rule;

              // eslint-disable-next-line default-case
              switch (rule.relationship) {
                case 'Spouse':
                  vm.data.masterPolicy.settings.enrolmentRules.spouse = rule;
                  break;
                case 'Child':
                  vm.data.masterPolicy.settings.enrolmentRules.child = rule;
                  break;
                case 'Parent':
                  vm.data.masterPolicy.settings.enrolmentRules.parents = rule;
                  break;
              }
            });
          }
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    // Toggle edit layout.

    function toggleEdit(flag) {
      vm.isEdit = flag;
    }

    // Cancel action.

    function cancel() {
      if (vm.isCreate || vm.isRenewal) {
        $state.go(
          'host.corporateMasterPolicies',
          {
            organizationId: vm.organizationId,
          },
          {
            reload: true,
          }
        );
      } else {
        abp.message.confirm(
          App.localize('UnsavedChangesConfirmation'),
          App.localize('AreYouSure'),
          (d) => {
            if (d) {
              toggleCancel(true);
            }
          }
        );
      }
    }

    // Cancel creation or edit.

    function toggleCancel(flag) {
      vm.toggleEdit(false);
      if (flag) {
        $state.reload();
      }
    }
  }
})();
