(() => {
  angular.module('finance').component('makePaymentCheckoutComponent', {
    templateUrl: require('./makePaymentCheckout.component.html'),
    controller: MakePaymentCheckoutComponent,
    controllerAs: 'vm',
    bindings: {
      selectedInvoices: '=',
      pageState: '=',
      calculateTotalAmount: '<',
    },
  });

  MakePaymentCheckoutComponent.$inject = [];

  function MakePaymentCheckoutComponent() {
    const vm = this;

    vm.loading = 0;

    vm.fail = fail;
    vm.success = success;

    init();

    function init() {}

    function fail() {
      vm.pageState = 3;
    }

    function success() {
      vm.pageState = 4;
    }
  }
})();
