(() => {
  angular
    .module('app')
    .controller(
      'finance.views.billingAccounts.billingAccountDetail.page',
      BillingAccountDetailController
    );

  BillingAccountDetailController.$inject = [
    '$stateParams',
    '$uibModal',
    'Hms.Finance.KnownBillingAccountEntityTypes',
    'abp.service.finance.billingAccount',
  ];

  function BillingAccountDetailController(
    $stateParams,
    $uibModal,
    enumFinanceKnownBillingAccountEntityTypes,
    billingAccountSvc
  ) {
    const vm = this;

    vm.loading = 0;
    vm.id = $stateParams.id;

    vm.permissions = {
      regenerateBillingAccountCode: abp.auth.isGranted(
        'HealthMetrics.Finance.BillingAccounts.RegenerateBillingAccountCode'
      ),
    };

    vm.enums = {
      knownBillingAccountEntityTypes: enumFinanceKnownBillingAccountEntityTypes,
    };

    vm.getBillingAccount = getBillingAccount;
    vm.regenerateBillingAccountCode = regenerateBillingAccountCode;
    vm.editAccountCode = editAccountCode;

    init();

    function init() {
      getBillingAccount();
    }

    function getBillingAccount() {
      const input = {
        id: vm.id,
      };

      vm.loading += 1;
      billingAccountSvc
      .getBillingAccountDetail(input)
      .success((data) => {
        vm.name = data.name;
        vm.accountType = data.type;
        vm.accountCode = data.accountCode;

        // Localize for display.
        vm.entityType = App.localize(
          _.find(
            enumFinanceKnownBillingAccountEntityTypes,
            (entityType) => entityType.id === data.entityType
          ).name
        );
        vm.tenancyCode = data.businessIdentifierCode;
        vm.accountOwner = data.accountOwner;
        vm.countryCode = data.countryCode;

        vm.bankAccounts = data.bankAccounts;
        vm.taxIdentifiers = data.taxIdentifiers;
        vm.billingAddress = data.billingAddress;
      });
      vm.loading -= 1;
    }

    function regenerateBillingAccountCode() {
      abp.message.confirm(
        App.localize('RegenerateNewAccountBillingCodeConfirmation'),
        App.localize('AreYouSure'),
        (d) => {
          if (d) {
            vm.loading += 1;
            billingAccountSvc
              .getNewBillingAccountCode({
                id: vm.id,
              })
              .success((data) => {
                vm.accountCode = data;
              })
              .finally(() => {
                vm.loading -= 1;
              });
          }
        }
      );
    }

    function editAccountCode() {
      $uibModal
      .open({
        templateUrl: require('./editAccountCodeModal/editAccountCode.modal.html'),
        controller: 'finance.views.billingAccounts.editAccountCodeModal as vm',
        backdrop: 'static',
        resolve: {
          billingAccount() {
            return {
              id: vm.id,
              accountCode: vm.accountCode
            };
          },
        },
      })
      .result.then((newAccountCode) => {
        if (newAccountCode)
          vm.accountCode = newAccountCode;
      });
    }
  }
})();
