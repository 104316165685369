(() => {
  angular.module('finance').component('makePaymentCartComponent', {
    templateUrl: require('./makePaymentCart.component.html'),
    controller: MakePaymentCartComponent,
    controllerAs: 'vm',
    bindings: {
      selectedInvoices: '=',
      pageState: '=',
      calculateTotalAmount: '<',
    },
  });

  MakePaymentCartComponent.$inject = ['$rootScope', 'abp.services.finance.curlec'];

  function MakePaymentCartComponent($root, curlecSvc) {
    const vm = this;

    vm.loading = 0;
    vm.totalAmount = 0;
    vm.currencyCode = 'MYR';

    vm.remove = remove;
    vm.cancel = cancel;
    vm.checkout = checkout;

    init();

    function init() {
      vm.totalAmount = vm.calculateTotalAmount(vm.selectedInvoices);
      loadRazorpayScript(() => {
        console.log('Razorpay script loaded successfully!');
      });
    }

    function cancel() {
      vm.pageState = 0;
    }

    function checkout() {
      let options = {
        key: 'rzp_test_8MJaB6xAzU2cB1',
        name: '',
        description: '',
        order_id: '',
        image: '',
        prefill: {
          name: '',
          email: '',
          contact: '',
        },
        notes: {
          address: '',
        },
        theme: {
          color: '#3399cc',
        },
        callback_url: $root.resolveAppPath('/Curlec/CurlecCallbackHandler'),
        redirect: true,
      };
      // Boolean whether to show image inside a white frame. (default: true)
      options.theme.image_padding = false;
      options.modal = {
        ondismiss: function () {
          console.log('This code runs when the popup is closed');
        },
        // Boolean indicating whether pressing escape key
        // should close the checkout form. (default: true)
        escape: true,
        // Boolean indicating whether clicking translucent blank
        // space outside checkout form should close the form. (default: false)
        backdropclose: false,
      };

      const inputInvoices = [];
      const inputBillingAccountIds = [];

      _.forEach(vm.selectedInvoices, (selectedInvoice) => {
        inputInvoices.push({
          id: selectedInvoice.id,
          appliedAmount: selectedInvoice.outstandingAmount,
        });
        inputBillingAccountIds.push(selectedInvoice.billingAccountId);
      });

      const input = {
        amount: vm.totalAmount,
        currencyCode: vm.currencyCode,
        invoices: inputInvoices,
        billingAccountIds: _.uniq(inputBillingAccountIds),
      };

      curlecSvc.createOrder(input).success((data) => {
        vm.orderId = data.orderId;
        console.log('success');

        if (data && data.orderId) {
          options.order_id = data.orderId;
          console.log('Order ID set successfully:', data.orderId);
          var rzp = new Razorpay(options);
          rzp.on('payment.success', function (response) {
            alert(response.razorpay_payment_id);
          });
          rzp.open();
        }
      });
    }

    function remove(invoice) {
      let index = vm.selectedInvoices.map((invoice) => invoice.id).indexOf(invoice.id);
      vm.selectedInvoices.splice(index, 1);
      vm.totalAmount = vm.calculateTotalAmount(vm.selectedInvoices);
    }

    function loadRazorpayScript(callback) {
      const script = document.createElement('script');
      script.src = 'https://checkout.razorpay.com/v1/checkout.js';
      script.onload = callback;
      document.head.appendChild(script);
    }
  }
})();
