import { module } from 'angular';
import _ from 'lodash';

(() => {
  angular
    .module('finance')
    .controller('finance.views.accountReceivables.index', AccountReceivablesPageController);

  AccountReceivablesPageController.$inject = [
    '$scope',
    '$state',
    '$stateParams',
    '$timeout',
    'financeConstants',
    'abp.services.finance.accountReceivables',
    'abp.services.finance.settings',
    'abp.services.finance.payments',
    'abp.services.finance.commonLookup',
    'Hms.Payments.PaymentMethod',
    'HealthMetrics.Finance.Payments.PaymentState',
    'abp.services.app.commonLookup',
    'abp.services.app.exportToExcel',
  ];

  function AccountReceivablesPageController(
    $scope,
    $state,
    $stateParams,
    $timeout,
    financeConsts,
    accountReceivablesSvc,
    settingSvc,
    paymentsAppSvc,
    commonLookupSvc,
    enumPaymentMethod,
    enumPaymentState,
    portalCommonLookupSvc,
    exportToExcelSvc
  ) {
    const vm = this;

    // Variable declaration

    vm.loading = 0;
    vm.activeView = 0;
    vm.excessPaymentTotalCount = 0;
    vm.paymentsHistoryTotalCount = 0;
    vm.depositsTotalCount = 0;

    vm.permissions = {
      createPayment: abp.auth.isGranted('HealthMetrics.Finance.Payments.Create'),
      editPayment: abp.auth.isGranted('HealthMetrics.Finance.Payments.Edit'),
      deletePayment: abp.auth.isGranted('HealthMetrics.Finance.Payments.Delete'),
    };

    vm.accountOwners = [];
    vm.countries = [];
    vm.billingAccounts = [];

    vm.gridOptions = {
      appScopeProvider: vm,
      paginationPageSizes: financeConsts.grid.defaultPageSizes,
      paginationPageSize: financeConsts.grid.defaultPageSize,
      paginationCurrentPage: 0,
      useExternalPagination: true,
      useExternalSorting: true,
      columnDefs: getGridOptionsColumnDefs(),
      onRegisterApi(gridApi) {
        $scope.gridApi = gridApi;
        $scope.gridApi.core.on.sortChanged($scope, (scope, callback) => {
          vm.requestParams.sorting =
            callback.length && callback[0].field
              ? `${callback[0].field} ${callback[0].sort.direction}`
              : null;
          getListingsData();
        });
        gridApi.pagination.on.paginationChanged(
          $scope,
          App.setPagination(vm.requestParams, getListingsData)
        );
      },
      data: [],
    };

    vm.dateRangeOptions = App.createDateRangePickerOptions();
    vm.paymentDateRangeModel = {
      startDate: null,
      endDate: null,
    };

    vm.bankAccountDepositDateRangeModel = {
      startDate: null,
      endDate: null,
    };
    
    vm.lastModificationDateRangeModel = {
      startDate: null,
      endDate: null,
    };

    vm.enums = {
      paymentMethod: enumPaymentMethod,
      paymentState: enumPaymentState
    };

    vm.requestParams = {
      countryCode: $stateParams.countryCode,
      accountOwnerId: $stateParams.accountOwnerId,
      billingAccountId: $stateParams.billingAccountId,
      minAmount: $stateParams.minAmount,
      maxAmount: $stateParams.maxAmount,
      virtualAccountNumber: $stateParams.virtualAccountNumber,
      transactionType: $stateParams.transactionType,
      status: $stateParams.status,
      skipCount: $stateParams.skipCount || 0,
      maxResultCount: $stateParams.maxResultCount || financeConsts.grid.defaultPageSize,
      sorting: $stateParams.sorting,
      isPaymentHistoryTab: $stateParams.isPaymentHistoryTab,
    };

    // Function binding

    vm.receiveNewPayment = receiveNewPayment;
    vm.setActiveView = setActiveView;
    vm.getGridOptionsColumnDefs = getGridOptionsColumnDefs;
    vm.registerStateParams = registerStateParams;
    vm.getExcessPayments = getExcessPayments;
    vm.getPaymentsHistory = getPaymentsHistory;
    vm.exportToExcel = exportToExcel;
    vm.getEligibleCountries = getEligibleCountries;
    vm.getUserDefaultCurrencyCode = getUserDefaultCurrencyCode;
    vm.getListingsTotalCount = getListingsTotalCount;
    vm.getListingsData = getListingsData;
    vm.getRequestInput = getRequestInput;
    vm.resetParams = resetParams;
    vm.deletePayment = deletePayment;
    vm.getSettings = getSettings;
    vm.setCurrencyCode = setCurrencyCode;
    vm.deleteBankAccountDeposit = deleteBankAccountDeposit;

    vm.lookupBillingAccount = lookupBillingAccount;

    // Function declaration

    init();

    function init() {
      localizePaymentMethod();
      localizePaymentState();
      getEligibleCountries();
      getUserDefaultCurrencyCode();
      getAccountOwners();
      getListingsTotalCount();
      getSettings();
      lookupBillingAccount();

      $timeout(() => {
        vm.gridOptions.paginationCurrentPage = Math.floor(
          vm.requestParams.skipCount / vm.requestParams.maxResultCount + 1
        );
      });

      if ($stateParams.activeView) {
        setActiveView($stateParams.activeView);
      }
    }

    function lookupBillingAccount(search) {
      const keyword = _.trim(search || '');
      if (keyword.length > 0 && keyword.length < 3) return;

      commonLookupSvc
        .getBillingAccounts({
          filter: keyword,
          maxResultCount: 5,
          id: vm.requestParams.billingAccountId,
          type: 1, // Debtor.
        })
        .success((data) => {
          if (data) {
            vm.billingAccounts = data.items;
          }
        });
    }

    function getGridOptionsColumnDefs() {
      const columnDefs = [
        {
          name: App.localize('Actions'),
          enableSorting: false,
          width: 100,
          headerCellTemplate: '<span></span>',
          cellTemplate: 'actionTemplate',
          visible: abp.auth.isGranted('HealthMetrics.Finance.Payments'),
        },
        {
          displayName: App.localize('Country'),
          field: 'countryCode',
          width: 30,
          cellTemplate: 'countryTemplate',
        },
        {
          displayName: App.localize('RecipientInformation'),
          field: 'recipientInformation',
          enableSorting: false,
          minWidth: 100,
          visible: vm.activeView === 0,
        },
        {
          displayName: App.localize('AccountOwner'),
          field: 'accountOwner',
          minWidth: 140,
          visible: vm.activeView === 1 || vm.activeView === 2,
        },
        {
          displayName: App.localize('BillingAccount'),
          field: 'billingAccount',
          minWidth: 110,
          cellTemplate: 'billingAccountTemplate',
          visible: vm.activeView === 1 || vm.activeView === 2,
        },
        {
          displayName: App.localize('ExcessPayment'),
          field: 'excessPaymentAmount',
          cellFilter: 'currencyFormat: row.entity.currencyCode',
          minWidth: 100,
          visible: vm.activeView === 1,
        },
        {
          displayName: App.localize('PaymentAmount'),
          field: 'appliedAmount',
          cellFilter: 'currencyFormat: row.entity.currencyCode',
          minWidth: 100,
          visible: vm.activeView === 1 || vm.activeView === 2,
        },
        {
          displayName: App.localize('TransactionAmount'),
          field: 'transactionAmount',
          cellFilter: 'currencyFormat: row.entity.currencyCode',
          minWidth: 100,
          visible: vm.activeView === 0,
        },
        {
          displayName: App.localize('TransactionAmount'), // duplicated due to PaymentListDto and BankAccountDepositListDto
          field: 'receivedAmount',
          cellFilter: 'currencyFormat: row.entity.currencyCode',
          minWidth: 100,
          visible: vm.activeView === 1 || vm.activeView === 2,
        },
        {
          displayName: App.localize('PaymentDate'),
          field: 'paymentDate',
          cellFilter: "momentFormat: 'L'",
          minWidth: 130,
          visible: vm.activeView === 1 || vm.activeView === 2,
        },
        {
          displayName: App.localize('VirtualAccountNumber'),
          field: 'virtualAccountNumber',
          minWidth: 100,
        },
        {
          displayName: App.localize('TransactionType'),
          field: 'transactionType',
          minWidth: 100,
          visible: vm.activeView === 1 || vm.activeView === 2,
        },
        {
          displayName: App.localize('Status'),
          field: 'status',
          minWidth: 100,
          visible: vm.activeView === 1 || vm.activeView === 2,
          cellTemplate: 'statusTemplate',
        },
        {
          displayName: App.localize('PaymentNumber'),
          field: 'paymentNumber',
          minWidth: 100,
          visible: vm.activeView === 1 || vm.activeView === 2,
        },
        {
          displayName: App.localize('Reference'),
          field: 'reference',
          minWidth: 100,
        },
        {
          displayName: App.localize('DetailDescription'),
          field: 'detailDescription',
          enableSorting: false,
          minWidth: 100,
          visible: vm.activeView === 1 || vm.activeView === 2,
        },
        {
          displayName: App.localize('DetailDescription'), // duplicated due to PaymentListDto and BankAccountDepositListDto
          field: 'depositInformation',
          enableSorting: false,
          minWidth: 100,
          visible: vm.activeView === 0,
        },
        {
          displayName: App.localize('Date'),
          field: 'depositDate',
          cellFilter: "momentFormat: 'L'",
          minWidth: 130,
          visible: vm.activeView === 0,
        },
        {
          displayName: App.localize('CreationTime'),
          field: 'creationTimeOffset',
          cellFilter: "momentFormat: 'L LT'",
          minWidth: 130,
        },
        {
          displayName: App.localize('LastModificationTime'),
          field: 'lastModificationTime',
          cellFilter: "momentFormat: 'L LT'",
          minWidth: 130,
          visible: vm.activeView === 1 || vm.activeView === 2,
        },
      ];

      return columnDefs;
    }

    function registerStateParams() {
      vm.requestParams.skipCount =
        (vm.gridOptions.paginationCurrentPage - 1) * vm.gridOptions.paginationPageSize;
      vm.requestParams.maxResultCount = vm.gridOptions.paginationPageSize;

      $state.transitionTo(
        $state.current,
        {
          countryCode: vm.requestParams.countryCode,
          accountOwnerId: vm.requestParams.accountOwnerId,
          billingAccountId: vm.requestParams.billingAccountId,
          minAmount: vm.requestParams.minAmount,
          maxAmount: vm.requestParams.maxAmount,
          virtualAccountNumber: vm.requestParams.virtualAccountNumber,
          transactionType: vm.requestParams.transactionType,
          status: vm.requestParams.status,
          skipCount: vm.requestParams.skipCount || 0,
          maxResultCount: vm.requestParams.maxResultCount || module.consts.grid.defaultPageSize,
          sorting: vm.requestParams.sorting,
          isPaymentHistoryTab: vm.requestParams.isPaymentHistoryTab,
          billingAccount: vm.requestParams.billingAccount,
        },
        {
          notify: false,
        }
      );
    }

    function receiveNewPayment() {
      $state.go('finance.createEditPayment');
    }

    function localizePaymentListingItems(data) {
      _.forEach(data.items, (payment) => {
        const transactionType = _.find(
          enumPaymentMethod,
          (paymentMethod) => paymentMethod.id === payment.transactionType
        );

        payment.transactionType = App.localize(`PaymentMethod_${transactionType.name}`);
      });
    }

    function getExcessPayments() {
      registerStateParams();

      const input = getRequestInput();
      vm.loading += 1;
      accountReceivablesSvc
        .getExcessPayments(input)
        .success((data) => {
          // Convert id data to dropdown values for transaction type.

          localizePaymentListingItems(data);

          vm.excessPaymentTotalCount = data.totalCount;
          vm.gridOptions.totalItems = data.totalCount;
          vm.gridOptions.data = data.items;
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function getPaymentsHistory() {
      registerStateParams();

      const input = getRequestInput();

      vm.loading += 1;
      accountReceivablesSvc
        .getPaymentsHistory(input)
        .success((data) => {
          // Convert id data to dropdown values for transaction type.

          localizePaymentListingItems(data);

          vm.paymentsHistoryTotalCount = data.totalCount;
          vm.gridOptions.totalItems = data.totalCount;
          vm.gridOptions.data = data.items;
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function getBankAccountDeposits() {
      registerStateParams();

      const input = getRequestInput();
      vm.loading += 1;
      accountReceivablesSvc
        .getBankAccountDeposits(input)
        .success((data) => {
          vm.bankAccountDepositsCount = data.totalCount;
          vm.gridOptions.totalItems = data.totalCount;
          vm.gridOptions.data = data.items;
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function exportToExcel() {
      if (vm.requestParams.billingAccountId) {
        const billingAccount = _.find(
          vm.billingAccounts,
          (acc) => acc.id === vm.requestParams.billingAccountId
        );

        vm.requestParams.billingAccount = `(${billingAccount.businessIdentifierCode}) ${billingAccount.name}`;
      }

      vm.requestParams.isPaymentHistoryTab = vm.tabIndex;

      registerStateParams();
      const input = getRequestInput();

      // Get the paymentStateString based on the paymentState.

      input.paymentState = input.status ?
        _.find(vm.enums.paymentState.values, { id: input.status }).name : null;

      // Get the paymentMethod based on the transactionType.

      input.paymentMethod = input.transactionType ?
        _.find(vm.enums.paymentMethod.values, { id: input.transactionType }).name :null;
        
      vm.exporting += 1;
      exportToExcelSvc
        .getAccountReceivablesToExcel(input)
        .success((data) => {
          App.downloadTempFile(data);
        })
        .finally(() => {
          vm.exporting -= 1;
        });
    }

    function deletePayment(paymentNumber) {
      abp.message.confirm(
        App.localize('DeletePaymentWarningMessage', paymentNumber),
        App.localize('AreYouSure'),
        (d) => {
          if (d) {
            paymentsAppSvc
              .deletePayment({
                paymentNumber,
              })
              .success(() => {
                abp.notify.success(App.localize('SuccessfullyDeleted'));
                getListingsTotalCount();
                getListingsData();
              });
          }
        }
      );
    }

    // Helper function.

    function setActiveView(viewId) {
      if (vm.activeView !== viewId) {
        vm.activeView = viewId;

        vm.gridOptions.paginationPageSize = financeConsts.grid.defaultPageSize;
        vm.gridOptions.paginationCurrentPage = 1;

        resetParams();
        getListingsData();
      }

      // Reset the column listing when the tab is changed.

      vm.gridOptions.columnDefs = getGridOptionsColumnDefs();
    }

    function getEligibleCountries() {
      commonLookupSvc.getCountries().success((data) => {
        if (data) {
          vm.countries = data.items;
        }
      });
    }

    function getAccountOwners() {
      commonLookupSvc.getAccountOwners().success((data) => {
        if (data) {
          vm.accountOwners = data.items;
        }
      });
    }

    function localizePaymentMethod() {
      _.forEach(vm.enums.paymentMethod, (paymentMethod) => {
        paymentMethod.displayName = App.localize(`PaymentMethod_${paymentMethod.name}`);
      });
    }

    function localizePaymentState() {
      _.forEach(vm.enums.paymentState, (paymentState) => {
        paymentState.displayName = App.localize(paymentState.name);
      });
    }

    function getUserDefaultCurrencyCode() {
      portalCommonLookupSvc
        .getCurrencyCodeByDefaultEligibleCountries()
        .success((data) => {
          vm.defaultCurrencyCode = data.value;
          vm.currencyCode = data.value;
        })
        .finally(() => {});
    }

    function getListingsTotalCount() {
      vm.loading += 1;
      accountReceivablesSvc
        .getListingsTotalCount()
        .success((data) => {
          vm.excessPaymentTotalCount = data.excessPaymentsCount;
          vm.paymentsHistoryTotalCount = data.paymentsHistoryCount;
          vm.depositsTotalCount = data.bankAccountDepositsCount;
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function setCurrencyCode(){
      if (vm.requestParams.accountOwnerId != null){
        vm.requestParams.countryCode = _.find(
          vm.accountOwners,
          (o) => o.id === vm.requestParams.accountOwnerId
        ).countryCode;
      }

      if (vm.requestParams.countryCode != null) {
        vm.currencyCode = _.find(
          vm.countries,
          (c) => c.countryCode === vm.requestParams.countryCode
        ).currencyCode;
      } else {
        vm.currencyCode = vm.defaultCurrencyCode;
      }
    }

    function getListingsData() {
      if (vm.activeView === 0) {
        vm.tabIndex = 0;
        getBankAccountDeposits();
      } else if (vm.activeView === 1) {
        vm.tabIndex = 0;
        getExcessPayments();
      } else if (vm.activeView === 2) {
        vm.tabIndex = 1;
        getPaymentsHistory();
      }
    }

    function getRequestInput() {
      const input = $.extend({}, vm.requestParams);

      if (vm.paymentDateRangeModel.startDate)
        input.paymentStartDate = vm.paymentDateRangeModel.startDate.format('YYYY-MM-DD');
      if (vm.paymentDateRangeModel.endDate)
        input.paymentEndDate = vm.paymentDateRangeModel.endDate.format('YYYY-MM-DD');

      if (vm.bankAccountDepositDateRangeModel.startDate)
        input.bankDepositStartDate =
          vm.bankAccountDepositDateRangeModel.startDate.format('YYYY-MM-DD');
      if (vm.bankAccountDepositDateRangeModel.endDate)
        input.bankDepositEndDate = vm.bankAccountDepositDateRangeModel.endDate.format('YYYY-MM-DD');

      if (vm.lastModificationDateRangeModel.startDate)
        input.lastModificationStartDate = vm.lastModificationDateRangeModel.startDate.format('YYYY-MM-DD');
      if (vm.lastModificationDateRangeModel.endDate)
        input.lastModificationEndDate = vm.lastModificationDateRangeModel.endDate.format('YYYY-MM-DD');

      return input;
    }

    function resetParams() {
      vm.paymentDateRangeModel = {
        startDate: null,
        endDate: null,
      };

      vm.bankAccountDepositDateRangeModel = {
        startDate: null,
        endDate: null,
      };

      vm.transactionDateRangeModel = {
        startDate: null,
        endDate: null,
      };

      vm.lastModificationDateRangeModel = {
        startDate: null,
        endDate: null,
      };

      vm.requestParams = {
        countryCode: undefined,
        accountOwnerId: undefined,
        billingAccountId: undefined,
        paymentStartDate: undefined,
        paymentEndDate: undefined,
        minAmount: undefined,
        maxAmount: undefined,
        virtualAccountNumber: undefined,
        transactionType: undefined,
        status: undefined,
        skipCount: 0,
        maxResultCount: financeConsts.grid.defaultPageSize,
        sorting: undefined,
        isPaymentHistoryTab: undefined,
        billingAccount: undefined,
      };
    }

    function getSettings() {
      vm.loading += 1;
      commonLookupSvc
        .getCommonSettings()
        .success((data) => {
          vm.hasManualKnockoff = data.manualKnockoff;
        })
        .finally();

      vm.loading -= 1;
    }

    function deleteBankAccountDeposit(bankAccountDepositId) {
      abp.message.confirm(
        App.localize('DeleteBankAccountDepositWarningMessage'),
        App.localize('AreYouSure'),
        (d) => {
          if (d) {
            accountReceivablesSvc
              .deleteBankAccountDeposit({
                bankAccountDepositId,
              })
              .success(() => {
                abp.notify.success(App.localize('SuccessfullyDeleted'));
                getListingsTotalCount();
                getListingsData();
              });
          }
        }
      );
    }
  }
})();
